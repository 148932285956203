import React from "react";

/**
 * A column in a [LanguageDefinition]{@link LanguageDefinition} to represent a passed value to configure
 * @param props
 * @param {string} props.label the label to go over this column in the form
 * @param {string} props.placeholder the value to act as the placeholder text in the input box
 * @param {string} props.value the value that the columns input is bound to
 * @param {function} props.onChange the method to call to update the parent state.
 * Normally [setNestedState]{@link LanguageDefinition#setNestedState}
 * or [setBaseState]{@link LanguageDefinition#setBaseState}
 * @return {JSX.Element}
 * @constructor
 */
const LanguageFormCol = (props) => {
    return (
        <div className="form-group col-md-6">
            <label className="font-weight-bold">{props.label}</label>
            <input
                type="text"
                required
                className="form-control"
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}/>
        </div>);
}

export {LanguageFormCol}