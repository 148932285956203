import React, { Component } from "react";
import MediaSection from "./media.component";


/**
 * @class
 * A Class to represent an Audio and Image Media Section joined.
 */
class UploadAudioAndImageSection extends Component {

  /**
   * @constructor
   * @param props
   * @param {function} props.updateAudioStateFunction callback when Audio state has changed
   * @param {function} props.updateImageStateFunction callback when Image state has changed
   * @param {string} props.elementId the id of the DBO the media belongs to
   * @param {APIInterface} props.APIInterface the Interface this Component has with the API
   */
  constructor(props) {
    super(props);

    this.onChangeAudio = this.onChangeAudio.bind(this);
    this.downloadAudio = this.downloadAudio.bind(this);
    this.deleteAudio = this.deleteAudio.bind(this);

    this.onChangeImage = this.onChangeImage.bind(this);
    this.downloadImage = this.downloadImage.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  /**
   * Calls on the parent object ot update their current Audio State
   * @param {module:MediaSectionComponent~MediaDetails} audioObject the details of the new audio
   */
  onChangeAudio = (audioObject) => {
    console.log(`AUDIO_OBJECT: ${JSON.stringify(audioObject)}`);
    this.props.updateAudioStateFunction(
      {
        audio: audioObject.media,
      },
      audioObject.mediaFile
    );
  };


  /**
   * Calls on the parent object ot update their current Image State
   * @param {module:MediaSectionComponent~MediaDetails} imageObject the details of the updated image
   */
  onChangeImage = (imageObject) => {
    this.props.updateImageStateFunction(
      {
        image: imageObject.media,
      },
      imageObject.mediaFile
    );
  };

  /**
   * Downloads contained audio to the calling machine
   * @summary Calls on the API to download the audio configured for the Parent's represented DBO
   * @return {Promise<*>}
   */
  async downloadAudio() {
    let response = await this.props.APIInterface.downloadAudio({
      element_id: this.props.elementId,
    });
    return response;
  }

  /**
   * Downloads contained image to the calling machine
   * @summary Calls on the API to download the image configured for the Parent's represented DBO
   * @return {Promise<*>}
   */
  async downloadImage() {
    let response = await this.props.APIInterface.downloadImage({
      element_id: this.props.elementId,
    });
    return response;
  }

  /**
   * Deletes the audio for the current DBO
   * @summary calls on the API to delete the audio configured for the Parent's represented DBO
   * @return {Promise<*>}
   */
  async deleteAudio() {
    let response = await this.props.APIInterface.deleteAudio({
      element_id: this.props.elementId,
    });
    return response;
  }

  /**
   * Deletes the image for the current DBO
   * @summary calls on the API to delete the image configured for the Parent's represented DBO
   * @return {Promise<*>}
   */
  async deleteImage() {
    let response = await this.props.APIInterface.deleteImage({
      element_id: this.props.elementId,
    });
    return response;
  }

  /**
   * Creates a DOM element with both an Audio and Image [Media Section]{@link module:MediaSectionComponent~MediaSection}
   * @return {JSX.Element} an UploadAudioAndImageSection Component
   */
  render() {
    return (
      <div>
        <h5 className="font-weight-bold">{this.props.children.sectionTitle}</h5>

        <div className="form-row ml-1">
          <MediaSection
            onChangeFile={this.onChangeImage}
            downloadMediaAPICall={this.downloadImage}
            deleteMediaAPICall={this.deleteImage}
            reloadPageAfterDelete={true}
            title="Image"
            mediaType={"image"}
          >
            {this.props.children.image}
          </MediaSection>

          <MediaSection
            onChangeFile={this.onChangeAudio}
            downloadMediaAPICall={this.downloadAudio}
            deleteMediaAPICall={this.deleteAudio}
            reloadPageAfterDelete={true}
            title="Audio"
            mediaType={"audio"}
          >
            {this.props.children.audio}
          </MediaSection>
        </div>
      </div>
    );
  }
}
export default UploadAudioAndImageSection