import React from "react";

/**
 * An input element that allows for the changing of a specific state item
 * @param elementTypeName For labeling purposes - The type of the element that will have it's name changed
 * @param name the current value of the state item to be changed
 * @param onChangeFunction the function to execute when the input is submit
 * @return {JSX.Element} an input element to take in a name
 */
const changeElementName = (elementTypeName, name, onChangeFunction) => {
  return (
    <div className="form-group">
      <label className="font-weight-bold">{elementTypeName} name:</label>
      <input
        type="text"
        required
        className="form-control"
        placeholder={name}
        value={name}
        onChange={onChangeFunction}
      />
    </div>
  );
};

export default changeElementName
