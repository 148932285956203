import React, { Component } from "react";
import { TableButton} from "../../Utils/Buttons";
import {Button, ButtonGroup} from "react-bootstrap";
import {ArrowDown, ArrowUp} from 'react-bootstrap-icons'

/**
 * @typedef Morpheme
 * @property {string} id the iD of the morpheme in the database
 * @property {number} position the position of a morpheme in the morpheme's list.
 * @property {string} morph_cree the text of the morpheme in the taught language
 * @property {string} morph_eng the text of the morpheme in english
 */

/**
 * @class
 * The sub component of a [Phrase Page]{@link ContentPhrase} that handles and contains the morphemes of the phrase
 */
class MorphemesTable extends Component {

  /**
   * @constructor
   * @param props the properties used to construct a morphemes table component
   */
  constructor(props) {
    super(props);

    this.updateParentState = this.updateParentState.bind(this);
    this.onChangeMorphemePosition = this.onChangeMorphemePosition.bind(this);
    this.onChangeMorphemeCree = this.onChangeMorphemeCree.bind(this);
    this.onChangeMorphemeEnglish = this.onChangeMorphemeEnglish.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  /**
   * When the position of a morpheme is changed, we need to shuffle the list to accommodate this.
   * @summary takes in the current position and direction of travel of a morpheme change, then
   * stores the appropriate surrounding elements in order to first shuffle the list and then change the
   * elements internal account of their position before calling for [update]{@link #updateParentState}
   * @param e
   * @param e.idx the index of the element that generated the event
   * @param {"up"|"down"} e.dir the direction we need to move the generating element
   *
   */
  onChangeMorphemePosition = (e) => {
    let updatedMorphemesList = this.props.children; //the new list
    let swpIdx = parseInt(e.idx); //the idx of the one we clicked the button on
    let dir = e.dir; //The direction we need to move it
    let targetIdx = -1; //The index of who we are swapping with. SHOULD NOT STAY -1, THIS IS FOR CATCHING ERRORS

    //Setup our Swap
    if(swpIdx === 0 && dir === 'up')
      targetIdx = updatedMorphemesList.length - 1;
     else if (swpIdx === updatedMorphemesList.length-1 && dir === 'down')
      targetIdx = 0;
     else if (dir === 'up')
      targetIdx = swpIdx - 1;
     else if (dir === 'down')
      targetIdx = swpIdx + 1;
     else
      console.error(`SWPi:${swpIdx} TARi: ${targetIdx} dir:${dir} len:${this.props.children.length}`);

    //SWPi:1 TARi:0 dir:UP

    //Grab the elements from the original list
    let swp = this.props.children[swpIdx];
    let target = this.props.children[targetIdx];


    //Switch the position _fields_ on the items for edge cases
    if(targetIdx === 0 && swpIdx === this.props.children.length) {
      swpIdx = 1;
      targetIdx = this.props.children.length + 1
    }

    //Switch the position _fields_ on the items
    target.position = swpIdx + 1;
    swp.position = targetIdx + 1;

    //swap the positions of the items themselves in the array
    updatedMorphemesList[swpIdx] = target;
    updatedMorphemesList[targetIdx] = swp;

    //Update the parent with the new list.
    this.updateParentState(updatedMorphemesList);
  };

  /**
   * Handles the change of a morphemes taught language text
   * @summary loads in the existing list, then determines the position of the target morpheme and updates it's
   * taught language text, then calls on [update]{@link #updateParentState}
   * @param e
   * @param {string} e.target.id in the format "ind_<IDX>" , the index of the generating morpheme
   * @param {string} e.target.value the updated text to assign to this area
   */
  onChangeMorphemeCree = (e) => {
    let updatedMorphemesList = this.props.children;
    let curIdx = e.target.id.split("_")[1];

    updatedMorphemesList[curIdx].morph_cree = e.target.value;
    this.updateParentState(updatedMorphemesList);
  };

  /**
   * Handles the change of a morphemes english language text
   * @summary loads in the existing list, then determines the position of the target morpheme and updates it's
   * english language text, then calls on [update]{@link #updateParentState}
   * @param e
   * @param {string} e.target.id in the format "eng_<IDX>" , the index of the generating morpheme
   * @param {string} e.target.value the updated text to assign to this area
   */
  onChangeMorphemeEnglish = (e) => {
    let updatedMorphemesList = this.props.children;
    let curIdx = e.target.id.split("_")[1];

    updatedMorphemesList[curIdx].morph_english = e.target.value;
    this.updateParentState(updatedMorphemesList);
  };

  /**
   * @summary Calls the method passed through props to update the state of the element containing a morphemes list
   * @param {Array<Morpheme>} morphemeList - the list of morphemes that make up this phrase
   */
  updateParentState = (morphemeList) => {
    this.props.updateStateFunction(morphemeList);
  };

  /**
   * Calls on the deletion of a morpheme from the table
   * @summary splices the element with the given ID out of the list and then calls for [update]{@link #updateParentState}
   * @param {number} idx the index of the morpheme to delete
   */
  onClickDelete = (idx) => {
    let updatedMorphemesList = this.props.children;

    updatedMorphemesList.splice(idx,1);

    this.updateParentState(updatedMorphemesList);
  };


  /**
   * Renders a Morphemes Table
   * @return {JSX.Element} the Morphemes Table
   * @see TableButton
   */
  render() {
    return (
      <div>
        <h5 className="font-weight-bold">Morphemes</h5>

        <table className="table table-striped table-bordered mt-4 mb-4">
          <thead className="thead-dark">
            <tr>
              <th scope="col" width="30">
                Position
              </th>
              <th scope="col">Language: Indigenous</th>
              <th scope="col">Language: English</th>
              <th scope="col" width="100">
                Options
              </th>
            </tr>
          </thead>

          <tbody>
            {this.props.children.map((val, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <ButtonGroup>
                      <Button className="btn-dark mb-2"
                      size={"sm"}
                      disabled={this.props.children.length < 2}
                      onClick={() => this.onChangeMorphemePosition({idx:idx,dir:'up'})}>
                      <ArrowUp className="btn-dark"/>
                    </Button>

                    <Button className="btn-dark mb-2"
                      size={"sm"}
                      disabled={this.props.children.length < 2}
                      onClick={() => this.onChangeMorphemePosition({idx:idx,position:val.position,dir:'down'})}>
                      <ArrowDown/>
                    </Button>
                    </ButtonGroup>

                  </td>
                  <td>
                    <input
                      type="text"
                      required
                      className="form-control"
                      id={"ind_"+idx}
                      placeholder={val.morph_cree}
                      value={val.morph_cree}
                      onChange={this.onChangeMorphemeCree}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      required
                      className="form-control"
                      id={"eng_" + idx}
                      placeholder={val.morph_english}
                      value={val.morph_english}
                      onChange={this.onChangeMorphemeEnglish}
                    />
                  </td>
                  <td>
                    <TableButton id={idx} onClick={() => this.onClickDelete(idx)}>
                      Delete
                    </TableButton>
                  </td>
                </tr>
              );
            }).sort((a,b) => (a.position - b.position))}
          </tbody>
        </table>
      </div>
    );
  }
}
export default MorphemesTable
