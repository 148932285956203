import React, { Component } from "react";
import UsersTable from "./usersTable";
import APIInterface from "./APIInterface";
import { unauthorizedAccessToPage } from "../../Utils/authorization";
import LoadingSpinner from "../../Utils/LoadingSpinner";
import {Breadcrumb} from "react-bootstrap";

/**
 * @class
 * Manages the currently approved (active) users of the system
 */
class UsersManagement extends Component {

  /**
   * @constructor
   * @param props the payload used to create a user management page
   */
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      errorMessage: "",
      showLoading: true,
    };
  }

  /**
   * Populates the page with user data upon mount.
   * @summary calls [getElement]{@link module:ManageUsers-API-Interface~getElement} to get all active users,
   * then sets the state to reflect the returned list or shows error
   * @return {Promise<void>}
   */
  async componentDidMount() {
    let activeUsers = await APIInterface.getElement();

    if (activeUsers.errorStatus === undefined) {
      this.setState({
        users: activeUsers,
        showLoading: false,
      });
    } else if (activeUsers.errorStatus === 401) {
      window.location = "/login";
    } else if (activeUsers.errorStatus === 403) {
      this.setState({
        errorMessage: unauthorizedAccessToPage,
      });
    } else {
      this.setState({
        errorMessage: activeUsers.errorMessage,
      });
    }
  }

  /**
   * Renders the UserManagement Page
   * @return {JSX.Element} the User Management Page
   * @see LoadingSpinner
   * @see UsersTable
   */
  render() {
    return (
      <div className="ml-3 mr-4">
        {this.state.showLoading && <LoadingSpinner top={'50%'} left={'50%'}/> }

        {!this.state.showLoading &&
        <div>
         <Breadcrumb>
           <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
           <Breadcrumb.Item active>Manage Users</Breadcrumb.Item>
         </Breadcrumb>
          <UsersTable
          userDelteCallback={(newList) => this.setState({users:newList})}>
            {this.state.users}
          </UsersTable>
        </div>
        }
      </div>
    );
  }
}
export default UsersManagement;
