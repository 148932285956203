import axios from "axios";
import { getAuthorizationHeadersObject } from "../../Utils/authorization";

/**
 * @module Text-API-Interface
 */

/**
 * @typedef Story
 * @property {Object} audioFile the definition of the audio FILE for the Story
 * @property {Object} audio the definition of the audio for the Story
 * @property {Object} imageFile the definition of the image FILE for a Story
 * @property {Object} image the definition of the image for the Story
 * @property {string} cnt_table_name the name of the Story in the database
 * @property {string} text_cree the taught language text of the story
 * @property {string} text_english the english language text of the story
 * @property {string} text_id the id of the text being represented
 * @property {string} cnt_pg_id the id of the parent content page
 */

/**
 * Requests a Story Page
 * @summary again, awfully named method. Calls on the Text API to provide the
 * @param {string} elementId the Id of the story we want to retrieve
 * @return {Promise<Story>|Promise<any>} The Request response data containing a Story definition
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getElement(elementId) {
  let getTextURL = process.env.REACT_APP_BACKEND_TEXT_CNT_URI + elementId;

  let textAPIResponse;
  try {
    textAPIResponse = await axios.request({
      method: "GET",
      url: getTextURL,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, refresh your page.",
    };
  }

  let text = textAPIResponse.data;
  return text;
}

/**
 * Builds the payload to construct Text in the database
 * @param textObject the full definition of a story/text object
 * @return {FormData} a curated payload to send to the text service
 */
function updateTextRequestObject(textObject) {
  const data = new FormData();
  data.append("audio", textObject.audioFile);
  data.append("image", textObject.imageFile);
  data.append("cnt_table_name", textObject.cnt_table_name);
  data.append("text_cree", textObject.text_cree);
  data.append("text_english", textObject.text_english);
  return data;
}



/**
 * Updates an existing story in the database
 * @summary [formats]{@link #updateTextRequestObject} and sends off a
 * @param props
 * @param {Story} props.fullElement the full definition of a story object
 * @return {Promise<any>} updates an existing story in the database
 */
async function updateElement(props) {
  let updateTextURL =
    process.env.REACT_APP_BACKEND_TEXT_CNT_URI +
    "update/" +
    props.fullElement.text_id;
  let requestBody = updateTextRequestObject(props.fullElement);

  try {
    let updateResponse = await axios.post(
      updateTextURL,
      requestBody,
      getAuthorizationHeadersObject()
    );
    return updateResponse;
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, try again.",
    };
  }
}

/**
 * Adds a new element to the database
 * @param {Story} props the full definition of the new Story to define
 * @return {Promise<any>}
 */
async function addElement(props){
  let addTextURL =
      process.env.REACT_APP_BACKEND_TEXT_CNT_URI +
      "add/";

  let addBody = {
    cnt_page_id: props.cnt_pg_id, // NOTE - THIS WAS NOT ME , THEY INTRODUCED THE NAMING INCONSISTENCY ORIGINALLY
    text_cree: props.text_cree,
    text_english:props.text_english,
    cnt_table_name:props.cnt_table_name,
    audio: props.audio,
    image: props.image,
  }
  try{
    return (await axios.post(
        addTextURL,
        addBody,
        getAuthorizationHeadersObject()
    )).data;
  } catch (error){
    return {
      errorStatus:error.response.status,
      errorMessage: error.response.body
    }
  }

}

/**
 * Gets a URL for a Story's Audio
 * @function getAudioFileURL
 * @param props
 * @param {string} props.element_id the ID of the story to get audio for
 * @return {string} a resource url for the story's audio
 */
function getAudioFileURL(props) {
  return (
    process.env.REACT_APP_BACKEND_TEXT_CNT_URI +
    "/media/audio/" +
    props.element_id
  );
}

/**
 * Gets a URL for a Story's Image
 * @function getImageFileURL
 * @param props
 * @param {string} props.element_id the ID of the Story to get the image for
 * @return {string} a resource url for the Story's image
 */
function getImageFileURL(props) {
  return (
    process.env.REACT_APP_BACKEND_TEXT_CNT_URI +
    "/media/image/" +
    props.element_id
  );
}

/**
 * Wraps The Call to download audio to the calling machine
 * @param props
 * @return {Promise<any>} the file download
 */
async function downloadAudio(props) {
  return await downloadMedia(props, "Audio");
}

/**
 * Wraps The Call to download an image to the host machine
 * @param props
 * @return {Promise<any>} the file download
 */
async function downloadImage(props) {
  return await downloadMedia(props, "Image");
}

/**
 * Preforms the call to get audio or image from the database
 * @summary takes in the type then uses the either the [Audio URL]{@link module:Text-API-Interface~getAudioFileURL}
 * or [Image URL]{@link module:Text-API-Interface~getImageFileURL} to request media from the database
 * @param props
 * @param {"Audio"|"Image"} mediaType the type of media to call for the download of
 * @return {Promise<any>} the file download
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function downloadMedia(props, mediaType) {
  let downloadAudioURL = "";
  if (mediaType === "Audio") {
    downloadAudioURL = getAudioFileURL(props);
  } else if (mediaType === "Image") {
    downloadAudioURL = getImageFileURL(props);
  }

  try {
    let fileResponse = await axios.request({
      method: "GET",
      url: downloadAudioURL,
      headers: getAuthorizationHeadersObject().headers,
      responseType: "blob",
    });
    return fileResponse;
  } catch (error) {
    return {
      errorStatus: error.response.status,
    };
  }
}

/**
 * Wraps the call to delete the audio configured for a Story
 * @param props
 * @return {Promise<any>}
 */
async function deleteAudio(props) {
  return await deleteMedia(props, "Audio");
}

/**
 * Wraps the Call to delete the image configured for a Story
 * @param props
 * @return {Promise<any>}
 */
async function deleteImage(props) {
  return await deleteMedia(props, "Image");
}

/**
 * The Call to delete media from the server for the passed Story
 * @summary takes in the type then uses either the [Audio URL]{@link module:Phrase-API-Interface~getAudioFileURL}
 * or [Image URL]{@link module:Phrase-API-Interface~getImageFileURL} to request deletion of media from the database
 * @param props
 * @param {"Audio"|"Image"} mediaType the type of the media to call for the deletion of
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function deleteMedia(props, mediaType) {
  let deleteAudioURL = "";
  if (mediaType === "Audio") {
    deleteAudioURL = getAudioFileURL(props);
  } else if (mediaType === "Image") {
    deleteAudioURL = getImageFileURL(props);
  }

  try {
    let deleteResponse = await axios.request({
      method: "DELETE",
      url: deleteAudioURL,
      headers: getAuthorizationHeadersObject().headers,
    });
    return deleteResponse;
  } catch (error) {
    return {
      errorStatus: error.response.status,
    };
  }
}

const textAPIInterface = {
  downloadAudio,
  downloadImage,
  deleteAudio,
  deleteImage,
  updateElement,
  addElement,
  getElement,
};

export default  textAPIInterface;