import React, { Component } from "react";
import LoadingSpinner from "../../Utils/LoadingSpinner";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import './contact-page.style.css';
import {Form} from "react-bootstrap";

class ContactPage extends Component{

    constructor(props) {
        super(props);
        this.state = {
            showLoading:true
        }
    }

    async componentDidMount(){
        this.setState({showLoading:false})
    }

    render() {
        return(
        <div className={"ml-3 mr-4"}>
            {this.state.showLoading && <LoadingSpinner top={'50%'} left={'50%'}/>}
            {!this.state.showLoading &&
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Contact Us</h1>
                <p>Feel free to contact us any time at <a href="mailto:nisotak.project@gmail.com">
                    nisotak.project@gmail.com</a> with questions and feedback.</p>

                <div className={"split left"}>
                    <h3>Report an Issue</h3>
                    <Form action="mailto:nisotak.project@gmail.com" method="post" encType={"text/plain"}>
                        <Form.Group className="mb-3">
                            <Form.Label>Title:</Form.Label>
                            <Form.Control type="text" placeholder="title" name={"title"}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control as="textarea" rows={4} name={"description"} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control className="btn-dark" type="submit"/>
                        </Form.Group>
                    </Form>
                </div>
                <div className={"split right bg-dark"}>
                    <h3 className={"text-light"}> Reporting an Issue...</h3>
                    <p> A good issue report includes: </p>
                    <ol>
                        <li>Description of the <i>desired</i> behavior</li>
                        <li>Description of the <i>observed</i> behavior</li>
                        <li>A list of the steps taken to <i>produce</i> the undesired behavior</li>
                        <li>Description of device you are using (i.e. iPhone, android, computer, etc)</li>
                    </ol>
                </div>
            </div>
            }

        </div>
        )
    }
}

export default ContactPage