/**
 * @module IDParser
 */

/**
 * Takes in the URL and tears the question and lesson ID's from it
 * @param {string} url the question page url in the format: host.net/lesson/<lessonId>/mcQuestion/<questionId>
 * @return {{question_id: string, lesson_id: string}} {question_id,lesson_id}
 */
function MCQuestionParser(url) {
  let brokenUrl = url.split("/");
  let questionid = brokenUrl[brokenUrl.length - 1];
  let lessonid = brokenUrl[brokenUrl.length - 3];

  return {
    question_id: questionid,
    lesson_id: lessonid,
  };
}

/**
 * Takes in a Text page Url and pulls out its contained urls
 * @param {string} url the url containing the Ids
 * @see breakContentPageLevelURL
 * @return {{text_id, contentPage_id, lesson_id}} {text_id,contentPage_id,lesson_id}
 */
function TextContentParser(url) {
  const ids = breakContentPageLevelURL(url);

  return {
    contentPage_id: ids.contentPage_id,
    lesson_id: ids.lesson_id,
    text_id: ids.elementid,
  };
}

/**
 * Takes in a Text page Url and pulls out its contained urls
 * @param {string} url the url containing the Ids
 * @see breakContentPageLevelURL
 * @return {{text_id, contentPage_id, lesson_id}} {text_id,contentPage_id,lesson_id}
 */
function PhraseContentParser(url) {
  const ids = breakContentPageLevelURL(url);

  return {
    contentPage_id: ids.contentPage_id,
    lesson_id: ids.lesson_id,
    phrase_id: ids.elementid,
  };
}

/**
 * Takes in a content page url and pulls out its contained urls
 * @param {string} url the url with ids in the format: host.net/lesson/<lesson_id>/contentPage/<contentPage_id>
 * @return {{contentPage_id, lesson_id}} {contentPage_id,lesson_id}
 */
function ContentPageParser(url) {
  let brokenUrl = url.split("/");
  let contentPage_id = brokenUrl[brokenUrl.length - 1];
  let lessonid = brokenUrl[brokenUrl.length - 3];

  return {
    contentPage_id: contentPage_id,
    lesson_id: lessonid,
  };
}

/**
 * Takes in a Content Page sub-item Url and returns its contained Ids
 * @method breakContentPageLevelURL
 * @param {string} url the url in the format: host.net/lesson/<lessonId>/contentPage/<contentPageId>/<phrase|text|/<content_id>
 * @return {{elementid, contentPage_id, lesson_id}} {contentPage_id,lesson_id,element_id}
 */
function breakContentPageLevelURL(url) {
  let brokenUrl = url.split("/");
  let elementid = brokenUrl[brokenUrl.length - 1];
  let contentPageid = brokenUrl[brokenUrl.length - 3];
  let lessonid = brokenUrl[brokenUrl.length - 5];

  return {
    contentPage_id: contentPageid,
    lesson_id: lessonid,
    elementid: elementid,
  };
}

/**
 * Takes in a Lesson URL and returns the contained lesson id
 * @param {string} url the URL in the format: host.net/lesson/<lesson_id>
 * @return {{lesson_id:string}} an Object containing a lesson id
 */
function LessonPageParser(url) {
  let brokenUrl = url.split("/");
  let lessonid = brokenUrl[brokenUrl.length - 1];

  return {
    lesson_id: lessonid,
  };
}

const urlParser ={
  MCQuestionParser,
  TextContentParser,
  PhraseContentParser,
  ContentPageParser,
  LessonPageParser,
};

export default urlParser;