/**
 * @module LanguageSpec
 */

/**
 * The labels for the side menu of the Learner App
 * @type {{search_label: string, settings_label: string, logout_label: string, home_label: string, browse_label: string, help_label: string, adventure_label: string}}
 */
const SideMenu = {
    home_label: "",
    browse_label: "",
    search_label: "",
    adventure_label: "",
    settings_label:"",
    help_label: "" ,
    logout_label: "",
    about_label: "",
}

/**
 * The Labels for the welcome page of the Learner App
 * @type {{signup_label: string, password_label: string, thank_you_label: string, welcome_label: string, select_server_label: string, select_language_label: string, login_label: string, username_label: string}}
 */
const WelcomePage = {
    welcome_label: "",
    login_label: "",
    username_label: "",
    password_label: "",
    signup_label: "",
    thank_you_label: "",
    select_language_label:"",
    select_server_label:"",
}

/**
 * the labels for the Lesson Page of the Learner App
 * @type {{next_label: string, level_beginner_label: string, level_label: string, quiz_label: string, begin_label: string, download_label: string, previous_label: string, difficulty_easy_label: string, show_hint_label: string, lessons_label: string, level_intermediate_label: string, lesson_label: string, difficulty_hard_label: string, quit_label: string, try_again_label: string, remove_download_label: string, hide_english_label: string, difficulty_normal_label: string, finish_label: string, story_label: string, hide_hint_label: string, difficulty_label: string, level_advanced_label: string, incorrect_label: string, return_to_lesson_label: string, result_label: string, show_english_label: string, correct_label: string}}
 */
const LessonPage = {
    lessons_label: "",
    lesson_label: "",
    begin_label: "",
    story_label: "",
    quiz_label: "",
    listen_label: "",
    download_label: "",
    remove_download_label: "",
    previous_label: "",
    next_label: "",
    finish_label:"",
    show_hint_label: "",
    hide_hint_label: "",
    return_to_lesson_label: "",
    quit_label: "",
    try_again_label: "",
    correct_label: "",
    incorrect_label: "",
    result_label: "",
    show_english_label: "",
    hide_english_label: "",
    level_label:"",
    level_beginner_label:"",
    level_intermediate_label:"",
    level_advanced_label:"",
    difficulty_label:"",
    difficulty_easy_label:"",
    difficulty_normal_label:"",
    difficulty_hard_label:"",
    topic_label: "",
    title_label: "",
    dialect_label: "",
    location_label: "",
    intro_label: "",
}

/**
 * The Labels for the Adventure Page of the Learner App
 * @type {{trade_label: string, progress_label: string}}
 */
const AdventurePage = {
    trade_label: "",
    progress_label: "",
}

/**
 * The Labels for the Search page of the Learner App
 * @type {{intro_label: string, limit_label: string, advanced_search_label: string, dialect_label: string, location_label: string, topic_label: string, hide_advanced_label: string, title_label: string}}
 * @deprecated - search page has no unique strings after the removal of advanced search in #148
 */
const SearchPage = {
    advanced_search_label: "",
    hide_advanced_label: "",
    topic_label: "",
    title_label: "",
    dialect_label: "",
    location_label: "",
    intro_label: "",
    limit_label: "",
}

/**
 * The labels for the Settings page of teh learner app
 * @type {{language_label: string, dark_mode_label: string}}
 */
const SettingsPage  ={
    dark_mode_label: "",
    language_label: "",
}

/**
 * The labels for the About and Help Pages
 * @type {{submit_label: string, description_label: string, about_label: string, title_label: string}}
 */
const AboutAndHelpPage = {
    about_label: "",
    title_label: "",
    description_label: "",
    submit_label: "",
}

/**
 * An object that represents the definition of a language
 * @type {{adventure_page: {}, settings_page: {}, knowledge_source: string, author: string, lesson_page: {}, name: string, side_menu: {}, location: string, welcome_page: {}, search_page: {}, misc: {}}}
 */
const LanguageSpec = {
    name: "",
    location: "",
    author: "",
    knowledge_source:"",

    side_menu: {},

    welcome_page: {},

    lesson_page: {},

    adventure_page: {},

    search_page: {},

    settings_page: {},

    about_and_help_page:{},

    misc: {}
}

module.exports = {AdventurePage:AdventurePage, LessonPage:LessonPage, SearchPage:SearchPage,SettingsPage:SettingsPage,
WelcomePage:WelcomePage,SideMenu:SideMenu,AboutAndHelpPage:AboutAndHelpPage, LanguageSpec:LanguageSpec};
