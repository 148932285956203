import axios from 'axios'
import {getAuthorizationHeadersObject} from "../../Utils/authorization";

/**
 * @module ManageLanguages-API-Interface
 */


/**
 * The Representation of a language in the Database.
 * @typedef  Language
 * @property {string} _id - the mongo id for the language
 * @property {string} name - the for the name of the language
 * @property {string} location - the location / region the language originates from
 * @property {string} author - the author of this language definition
 * @property {string} knowledge_source - the source of the knowledge required to create this language
 * @property side_menu - Object for all side-menu labels
 * @property welcome_page - Object for all welcome page labels
 * @property lesson_page - Object for all lesson page labels
 * @property adventure_page - Object for all adventure page labels
 * @property search_page - Object for all search page labels
 * @property settings_page - Object for all settings page labels
 * @property about_and_help_page - Object for all about and help page labels
 * @property misc - currently empty - created to hold any un-sortable labels that may pop up in the future.
 */

/**
 * Calls for the addition of a new language to the database
 * @param {Language} newLanguage - The language to add
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function addLanguage(newLanguage){
    let addLanguageUrl = process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + 'languages/add';
    let newLangBody = {
        name:newLanguage.name,
        location:newLanguage.location,
        author:newLanguage.author,
        knowledge_source:newLanguage.knowledge_source,
        side_menu:newLanguage.side_menu,
        welcome_page:newLanguage.welcome_page,
        lesson_page:newLanguage.lesson_page,
        search_page:newLanguage.search_page,
        settings_page:newLanguage.settings_page,
        adventure_page:newLanguage.adventure_page,
        about_and_help_page:newLanguage.about_and_help_page
    }

    try{
        return (await axios.post(
            addLanguageUrl,
            newLangBody,
            getAuthorizationHeadersObject()
        )).data;
    } catch (error){
        return {
            errorStatus: error.response.status,
            errorMessage: `Error: ${error.response.data}`
        }
    }
}

/**
 * Calls for the update of an existing language to be the given language
 * @param {Language} existingLanguage - the language that was modified.
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function updateLanguage(existingLanguage){
    let updateLanguageUrl = process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "languages/update/" + existingLanguage._id

    let langBody = {
        name:existingLanguage.name,
        location:existingLanguage.location,
        author:existingLanguage.author,
        knowledge_source:existingLanguage.knowledge_source,
        side_menu:existingLanguage.side_menu,
        welcome_page:existingLanguage.welcome_page,
        lesson_page:existingLanguage.lesson_page,
        search_page:existingLanguage.search_page,
        settings_page:existingLanguage.settings_page,
        adventure_page:existingLanguage.adventure_page,
        about_and_help_page:existingLanguage.about_and_help_page
    }

    try {
      return await axios.post(
          updateLanguageUrl,
          langBody,
          getAuthorizationHeadersObject()
      );
    } catch (error){
        return {
            errorStatus: error.response.status,
            errorMessage: `Error: ${error.response.data}`
        }
    }
}

/**
 * Calls for the retrieval of a single language
 * @param {string} languageId - the ID of the language to get.
 * @return {Promise<Language>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getLanguage(languageId){
    let getLanguageUrl = process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "languages/" + languageId
    try {
        return await axios.get(getLanguageUrl,getAuthorizationHeadersObject())
    } catch (error){
        return{
            errorStatus: error.response.status,
            errorMessage: `Error: ${error.response.data}`
        }
    }
}

/**
 * Calls for the retrieval all of the languages
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getAllLanguages(){
    let getLanguagesUrl = process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "languages"
    try {

        let header = getAuthorizationHeadersObject()
        header.headers['verbosity'] = 'low';
        return await axios.request({
            method: "GET",
            url: getLanguagesUrl,
            headers: header.headers
        });

    } catch (error){
        return {
            errorStatus: error.response.status,
            errorMessage: `Error: ${error.response.data}`
        }
    }
}

/**
 * Calls for the deletion a language
 * @param {string} langId - the ID of the language to delete.
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function deleteLanguage(langId){
    let deleteUrl = process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "languages/" + langId

    try{
        return await axios.request({
            method: 'DELETE',
            url: deleteUrl,
            headers: getAuthorizationHeadersObject().headers
        });
    } catch (error){
        return {
            errorStatus: error.response.status,
            errorMessage: `Error: ${error.response.data}`
        }
    }
}

/**
 * Changes the published status of the lesson
 * @param props
 * @param {string} props.lang_id the id of the language to set as published/unpublished
 * @param {boolean} props.doPublish true/false do we publish this lesson?
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function changePublished(props){
    try{
        let publishURL = process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI+ 'languages/publish/' + props.lang_id;
        return (await axios.post(
            publishURL,
            props,
            getAuthorizationHeadersObject()
        )).data;

    }catch (error){
        console.error(`FAILED TO CHANGE PUBLISH: ${JSON.stringify(error)}`);
        return{
            errorStatus: error.response.status,
            errorMessage: error.response
        }
    }
}


const languageManAPIInterface = {
    addLanguage,
    getAllLanguages,
    deleteLanguage,
    getLanguage,
    updateLanguage,
    changePublished,
}

export default languageManAPIInterface;