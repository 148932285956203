import React from "react";

/**
 * The definition of a generic transparent button
 * @param props properties this button should have
 * @param {true|false} props.disabled is this button disabled?
 * @param {function} props.onClick the function this button should call on click
 * @param props.children the children of this dom element
 * @return {JSX.Element} A new transparent button
 */
export const TransparentButton = (props) => {
  return (
    <div className="form-group col-sl-2">
      <button className="btn btn-outline-dark"
              disabled={props.disabled}
              onClick={props.onClick}>
        {props.children}
      </button>
    </div>
  );
};

/**
 * Formerly Tabble button, this is a button that goes inside of a table
 * @param props properties this button should have
 * @param props.id the ID this button element should have
 * @param {function} props.onClick the function to call on click of button
 * @param props.children the children of this dom element
 * @return {JSX.Element} a new table button
 */
export const TableButton = (props) => {
  return (
    <button
      className="btn btn-outline-dark mr-2"
      id={props.id}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
