import React, { Component } from "react";
import APIInterface from "./APIInterface";
import "./login.style.css";

/**
 * The Page to Log a user into the system
 * @class
 */
class Login extends Component {

  /**
   * @constructor
   * @param props the payload used to construct the login page
   */
  constructor(props) {
    super(props);

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      email: "",
      password: "",
      submitButtonText: "Login",
      errorMessage: "",
    };
  }

  /**
   * Changes state to reflect the input value of the email textbox
   * @param e
   * @param {string} e.target.value the value inside the email text-box
   */
  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  /**
   * Changes state to reflect input value of the Password textbox
   * @param e
   * @param {string} e.target.value the value inside the email text-box
   */
  onChangePassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  /**
   * Navigates the user to the register page
   */
  onClickRegister = () => {
    window.location = "/register";
  };

  /**
   * Attempts the login
   * @summary Puts username/password inside of a 3rd redundant varialbe (don't ask why, I don't know and I don't do it
   * that way), then calls on the API to [Login]{@link module:Login-API-Interface~login} the user. On success, the user
   * is given a *role* and an *access token* which will determine their ability to use the app. Otherwise, they are
   * notified of reason for login failure.
   * @return {Promise<void>}
   */
  async onSubmit() {
    this.setState({
      submitButtonText: "Please wait...",
      errorMessage: "",
    });

    let loginResult = await APIInterface.login({
      fullElement: this.state,
    });
    console.log(`errorMessage: ${loginResult.errorMessage}`);
    if (loginResult.errorStatus === undefined) {
      if (loginResult.data.user_role === "ReadOnly") {
        this.setState({
          errorMessage:
            "Your account is authorized to read from the API only. If you want to login to the platform, please contact the administrator to change your access policy.",
          submitButtonText: "Login",
        });
      } else {
        localStorage.setItem("access_token", loginResult.data.access_token);
        localStorage.setItem("user_role", loginResult.data.user_role);
        window.location = "/";
      }
    } else if (loginResult.errorStatus === 403) {
      this.setState({
        errorMessage:
          "Your account is still pending approval. Please, come back later.",
        submitButtonText: "Login",
      });
    } else {
      this.setState({
        errorMessage: loginResult.errorMessage,
        submitButtonText: "Login",
      });
    }
  }

  /**
   * Renders a Login Page
   * @return {JSX.Element} the Login Page
   */
  render() {
    return (
      <div className="ml-3 mr-4">
        <h3 className="mb-4">Login</h3>

        <div className="form-group ">
          <label className="font-weight-bold">Email</label>
          <input
            type="text"
            required
            className="form-control col-md-4"
            placeholder={this.state.email}
            value={this.state.email}
            onChange={this.onChangeEmail}
          />
        </div>
        <div className="form-group ">
          <label className="font-weight-bold">Password</label>
          <input
            name="password"
            type="password"
            required
            className="form-control col-md-4"
            placeholder={this.state.password}
            value={this.state.password}
            onChange={this.onChangePassword}
          />
        </div>
        <div className="form-group">
          <button className="btn btn-dark" onClick={this.onSubmit}>
            {this.state.submitButtonText}
          </button>
          <button
            className="btn btn-dark registerButton"
            onClick={this.onClickRegister}
          >
            {"Register"}
          </button>
        </div>
        <label style={{ color: "red" }}>{this.state.errorMessage}</label>
      </div>
    );
  }
}
export default Login;