import React from "react";
import { TransparentButton } from "../Buttons";

/**
 * The means to display audio
 * @todo this can probably be using the [uploadMediaSection]{@link module:MediaManagementElements} component
 * @module AudioManagementElements
 */

/**
 * The DOM element to display options for an audio file only
 * @function AudioOptions
 * @param props
 * @param {function} props.onClickDownload the callback to download audio
 * @param {string} props.downloadButtonText the text to display on the download button
 * @param {function} props.onclickUploadFile the callback to upload audio to the server
 * @param {string} props.uploadFileButtonText the text to display on the upload button
 * @param {function} props.onClickDeleteFile the callback to delete audio from the server
 * @param {string} props.deleteButtonText the text to display on the delete button
 * @return {JSX.Element} an AudioOptions component
 */
export const AudioOptions = (props) => {
  if (props.children === undefined) return <></>;
  if (props.showOptions === false) return <></>;

  return (
    <div className="form-row ">
      <TransparentButton onClick={props.onClickDownload}>
        {props.downloadButtonText}
      </TransparentButton>
      <TransparentButton onClick={props.onclickUploadFile}>
        {props.uploadFileButtonText}
      </TransparentButton>
      <TransparentButton onClick={props.onClickDeleteFile}>
        {props.deleteButtonText}
      </TransparentButton>
    </div>
  );
};
