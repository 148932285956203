import React, {Component} from "react";
import correctAnswerDropdown from "./correctAnswerDropdown";
import {TableButton, TransparentButton} from "../../Utils/Buttons";
import AnswerModal from "./answerModal.component";
import urlParser from "../../Utils/parseIdsFromURL";
import ConfirmDelete from "../../Utils/confirmDelete.component";
import AudioPlayer from "../../Utils/Audio/audioPlayer.component";
import {Button, ButtonGroup} from "react-bootstrap";
import {ArrowDown, ArrowUp} from "react-bootstrap-icons";

/**
 * @class
 * A Sub component of a [Question]{@link MCQuestion} that displays it's [Answers]{@link AnswerModal}.
 */
class QuestionAnswers extends Component {

  /**
   * @constructor
   * @param props
   * @param {module:Question-API-Interface~QuizOption[]} props.children an array of Quiz Options/Answers
   * @param {function} props.updateStateFunction a callback to [update]{@link MCQuestion#updateOptions} the parent elements state with
   */
  constructor(props) {
    super(props);

    this.updateParentState = this.updateParentState.bind(this);
    this.blankOption = this.blankOption.bind(this);
    this.onClickAddOption = this.onClickAddOption.bind(this);
    this.onChangeOptionTextCree = this.onChangeOptionTextCree.bind(this);
    this.onChangeOptionTextEng = this.onChangeOptionTextEng.bind(this);
    this.onClickDeleteOption = this.onClickDeleteOption.bind(this);
    this.onConfirmDeleteOption = this.onConfirmDeleteOption.bind(this);
    this.onChangeCorrectOption = this.onChangeCorrectOption.bind(this);
    this.onClickAudioOptions = this.onClickAudioOptions.bind(this);
    this.closeAudioModal = this.closeAudioModal.bind(this);
    this.closeDeleteConfirmationModal = this.closeDeleteConfirmationModal.bind(
      this
    );
    this.updateAnswerOptionFromModal = this.updateAnswerOptionFromModal.bind(
      this
    );

    let elementsId = urlParser.MCQuestionParser(window.location.href);

    this.state = {
      showAudioModal: false,
      showDeleteConfirmationModal: false,
      question_id: elementsId.question_id,
      deleteItemIndex: undefined,
      reGetAudio:false,
    };
  }

  /**
   * Wrapper for the [updateStateFunction]{@link MCQuestion#updateOptions} that updates the element's parents state
   * @param {module:Question-API-Interface~QuizOption[]} optionsList The updated list of QuizOptions
   */
  updateParentState = (optionsList) => {
    this.props.updateStateFunction(optionsList);
  };

  /**
   * Constructs a blank Quiz Option to add to the list of options
   * @function blankOption
   * @return {module:Question-API-Interface~QuizOption} a blank quiz option
   */
  blankOption = () => {
    return {
      audio:undefined,
      is_correct: false,
      text_cree: "",
      text_eng: "",
    };
  };

  /**
   * Adds a new option to the list of options
   */
  onClickAddOption = (_) => {
    let updatedOptionsList = this.props.children;
    updatedOptionsList.push(this.blankOption());
    this.updateParentState(updatedOptionsList);
  };

  /**
   * Changes the taught language text for a [QuizOption]{@link module:Question-API-Interface~QuizOption}
   * @param e
   * @param {string} e.target.value the new value for a QuizOption's taught language text
   * @param {number} e.target.id the QuizOption's position among the other options
   */
  onChangeOptionTextCree = (e) => {
    let updatedOptionsList = this.props.children;
    updatedOptionsList[e.target.id].text_cree = e.target.value;
    this.updateParentState(updatedOptionsList);
  };

  /**
   * Changes the english text for a [QuizOption]{@link module:Question-API-Interface~QuizOption}
   * @param e
   * @param {string} e.target.value the new value for a QuizOption's english text
   * @param {number} e.target.id the QuizOption's position among the other options
   */
  onChangeOptionTextEng = (e) => {
    let updatedOptionsList = this.props.children;
    updatedOptionsList[e.target.id].text_eng = e.target.value;
    this.updateParentState(updatedOptionsList);
  };

  /**
   * Reveals the Delete Modal to delete a quiz option
   * @param e
   * @param {number} e.target.id the position of the QuizOption in the array
   */
  onClickDeleteOption = (e) => {
    this.setState({
      showDeleteConfirmationModal: true,
      deleteItemIndex: e.target.id,
    });
  };

  /**
   * Deletes a [QuizOption]{@link module:Question-API-Interface~QuizOption} from the list of options.
   * @param {string} elementId the ID of the quiz option to delete
   * @see #updateParentState
   */
  onConfirmDeleteOption = (elementId) => {
    this.setState({
      showDeleteConfirmationModal: false,
      deleteItemIndex: undefined,
    });

    let updatedOptionsList = this.props.children;
    updatedOptionsList.splice(elementId, 1);
    this.updateParentState(updatedOptionsList);
  };

  /**
   * Updates which of the quiz options is to be considered correct
   * @param e
   * @param {number} e.target.value the index of the answer which is correct
   * @see [CorrectAnswerDropdown]{@link module:CorrectAnswerDropdown}
   */
  onChangeCorrectOption = (e) => {
    let updatedOptionsList = this.props.children;
    // Must clear the previous correct answer
    for (let i = 0; i < updatedOptionsList.length; i++) {
      updatedOptionsList[i].is_correct = false;
    }
    if (e.target.value >= 0) {
      updatedOptionsList[e.target.value].is_correct = true;
    }
    this.updateParentState(updatedOptionsList);
  };

  onChangeOptionPosition = (e) => {
    let updatedOptionsList = this.props.children;

    this.updateParentState(updatedOptionsList);
    let swpIdx = parseInt(e.idx); //the idx of the one we clicked the button on
    let dir = e.dir; //The direction we need to move it
    let targetIdx = -1; //The index of who we are swapping with. SHOULD NOT STAY -1, THIS IS FOR CATCHING ERRORS

    //Setup our Swap
    if(swpIdx === 0 && dir === 'up')
      targetIdx = updatedOptionsList.length -1;
    else if (swpIdx === updatedOptionsList.length-1 && dir === 'down')
      targetIdx = 0;
    else if (dir === 'up')
      targetIdx = swpIdx - 1;
    else if (dir === 'down')
      targetIdx = swpIdx + 1;
    else
      console.error(`SWPi:${swpIdx} TARi: ${targetIdx} wtf`);

    //Grab the elements from the original list
    let swp = this.props.children[swpIdx];
    let target = this.props.children[targetIdx];

    //swap the positions of the items themselves in the array
    updatedOptionsList[swpIdx] = target;
    updatedOptionsList[targetIdx] = swp;

    this.updateParentState(updatedOptionsList);
  }

  /**
   * Reveals the [Audio]{@link AnswerModal} to control audio
   * @param {string} e.target.id the position of the option to adjust audio for among the other options
   */
  onClickAudioOptions = (e) => {
    this.setState({
      showAudioModal: parseInt(e.target.id),
    });
  };

  /**
   * closes the [Audio]{@link AnswerModal} controls modal
   */
  closeAudioModal = () => {
    this.setState({
      showAudioModal: false,
    });
  };

  /**
   * Closes the [Delete Modal]{@link ConfirmDelete}
   */
  closeDeleteConfirmationModal = () => {
    this.setState({
      showDeleteConfirmationModal: false,
    });
  };

  /**
   * Updates the List of options
   * @summary Takes in an updated definition of the answer list, and knows of the current updated list,
   * then builds a new list by combining the two of them to represent a new state, and
   * calls [updateParentState]{@link class:AnswerOptionsSection~updateParentState}
   * Used by the [AnswerModal]{@link AnswerModal}
   * @param {number} answerIdx this is the index of the target answer in the current list
   * @param {Array<module:Question-API-Interface~QuizOption>} updatedOptionsServerState the updated list of quiz options
   */
  updateAnswerOptionFromModal = (answerIdx, updatedOptionsServerState) => {
    // Not all answer options are recorded in the server yet
    // But only the one saved must be updated in the page state
    let currentOptionsList = this.props.children;
    let updatedOptionsList = []; //A blank list that will be structured with the updated definition using current/updated lists
    let serverOptIndex = 0;

    //For each of the current options in the list
    for (let index = 0; index < currentOptionsList.length; index++) {

      //If the ID of the observed answer is "" and the passed answerIdx is not the observed index,
      //then it is a previously unknown answer option - add it.
      if (currentOptionsList[index]._id === "" && answerIdx !== index) {
        updatedOptionsList.push(currentOptionsList[index]);
        //If it has an Id, but the index is the one passed, It has been updated - push the updated def to the
        // new list
      } else if (answerIdx === index) {
        updatedOptionsList.push(updatedOptionsServerState[serverOptIndex]);
        serverOptIndex++;
        // Otherwise, this is not updated, and not new, so it is just existing and should just be added back where
        //it was
      } else {
        updatedOptionsList.push(currentOptionsList[index]);
        serverOptIndex++;
      }
    }

    this.updateParentState(updatedOptionsList);
  };

  /**
   * Renders the Options Section
   * @return {JSX.Element} The Options table section
   * @see AnswerModal
   */
  render() {
    return (
      <div>
        <h5 className="font-weight-bold">Answer Options:</h5>

        <table className="table table-striped table-bordered mt-4 mb-4">
          <thead className="thead-dark">
            <tr>
              <th scope="col" width="30">
                Position
              </th>
              <th>
                Answer
              </th>
              <th scope="col">Language: Indigenous</th>
              <th scope="col">Language: English</th>
              <th scope="col" width="40">
                Audio
              </th>
              <th scope="col" width="200">
                Options
              </th>
            </tr>
          </thead>

          <tbody>
            {this.props.children.map((val, idx) => {
              return (
                <tr key={idx}>
                  <td>
                      <ButtonGroup>
                        <Button className="btn-dark mb-2"
                                size={"sm"}
                                onClick={() => this.onChangeOptionPosition({idx:idx,dir:'up'})}>
                          <ArrowUp className="btn-dark"/>
                        </Button>

                        <Button className="btn-dark mb-2"
                                size={"sm"}
                                onClick={() => this.onChangeOptionPosition({idx:idx,position:val.position,dir:'down'})}>
                          <ArrowDown/>
                        </Button>
                      </ButtonGroup>
                  </td>

                  <td className="text-center">
                    {String.fromCharCode(65 + idx)}
                  </td>
                  <td>
                    <input
                      type="text"
                      required
                      className="form-control"
                      name="Cree"
                      id={idx}
                      placeholder={val.text_cree}
                      value={val.text_cree}
                      onChange={this.onChangeOptionTextCree}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      required
                      className="form-control"
                      name="English"
                      id={idx}
                      placeholder={val.text_eng}
                      value={val.text_eng}
                      onChange={this.onChangeOptionTextEng}
                    />
                  </td>
                  <td>
                    {val.audio !== undefined &&
                      <AudioPlayer
                          audioProps={{question_id: this.state.question_id, option_id: val._id ?? 'newOption'}}
                          file_name={val?.audio.file_name}
                      />
                    }
                    { val.audio === undefined && <p>None</p> }
                  </td>
                  <td>
                    <TableButton id={idx} onClick={this.onClickDeleteOption}>
                      Delete
                    </TableButton>
                    <ConfirmDelete
                      id={this.state.deleteItemIndex}
                      show={this.state.showDeleteConfirmationModal}
                      closeModalCallback={this.closeDeleteConfirmationModal}
                      deleteFunction={this.onConfirmDeleteOption}
                    />
                    <TableButton id={idx} onClick={this.onClickAudioOptions}>
                      Audio
                    </TableButton>
                    <AnswerModal
                      id={idx}
                      show={this.state.showAudioModal === idx}
                      closeModalCallback={this.closeAudioModal}
                      updateOptionsCallback={this.updateAnswerOptionFromModal}
                      question_id={this.state.question_id}
                      answerLetter={String.fromCharCode(65 + idx)}
                      answerObject={val}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="form-row mb-3">
          <TransparentButton onClick={this.onClickAddOption}>
            Add New Answer
          </TransparentButton>
          <label className="font-weight-bold ml-3">Correct Answer: </label>
          {correctAnswerDropdown(
            this.props.children,
            this.onChangeCorrectOption
          )}
        </div>
      </div>
    );
  }
}
export default QuestionAnswers