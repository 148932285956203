import React, { Component } from "react";
import APIInterface from "./APIInterface";
import "./registration.style.css";

/**
 * The Registration page component
 * @class
 */
class Registration extends Component {

  /**
   * @constructor
   * @param props the needed payload to construct a Registration page
   */
  constructor(props) {
    super(props);

    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onClickLoginPage = this.onClickLoginPage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      email: "",
      username: "",
      password: "",
      passwordsMatch: false,
      submitButtonText: "Register",
      errorMessage: "",
      successMessage: "",
      accountCreated: false,
    };
  }

  /**
   * Changes the state to reflect the input of the email textbox
   * @param e
   * @param {string} e.target.value the text input to the email textbox
   */
  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  /**
   * Changes the state to reflect the input of the username textbox
   * @param e
   * @param {string} e.target.value the text input to the username textbox
   */
  onChangeUsername = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  /**
   * Changes the state to reflect the value input to the password textbox
   * @param e
   * @param {string} e.target.value the text input to the password textbox
   */
  onChangePassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  /**
   * Verifies that the password is what the user meant to type
   * @summary checks the input of a second textbox against the value configured for password
   * @param e
   */
  onChangeVerifyPassword = (e) => {
    if (e.target.value === this.state.password) {
      this.setState({
        passwordsMatch: true,
      });
    } else {
      this.setState({
        passwordsMatch: false,
      });
    }
  };

  /**
   * Navigates to the login page
   */
  onClickLoginPage = () => {
    window.location = "/login";
  };

  /**
   *
   * @param {string} field the field that shouldn't be empty
   */
  generateEmptyWarning(field){
    return `Please enter a ${field}`
  }

  /**
   * Validates the length of the password
   * @returns {string}
   */
  validatePassword(){
    if(this.state.password.length < 8)
      return "Password must be at least 8 characters long";
    else if (this.state.passwordsMatch === false)
      return "Passwords must match"
    return "";
  }

  /**
   * Validates the fields of the form
   */
  validateForm(){
    let errorMsg = this.validatePassword();
    //Set the error message
    if(errorMsg.length !== 0) {
      this.setState({errorMessage: errorMsg});
      return false;
    }
    return true;
  }

  /**
   * Registers a user
   * @summary encapsulates state into a new element (again, why I do not know, this is a payload of 3 items...) then
   * calls on the API to [Register a User]{@link module:Registration-API-Interface~registerUser} and alerts the user
   * of success/status of the registration
   * @return {Promise<void>}
   */
  async onSubmit(e) {
    e.preventDefault();
    let passwordIsValid = this.validatePassword();
    if(passwordIsValid.length === 0) {
      this.setState({
        submitButtonText: "Please wait...",
        successMessage: "",
        errorMessage: "",
      });
        let registrationResult = await APIInterface.registerUser({
          fullElement: this.state,
        });

        if (registrationResult.errorStatus === undefined) {
          this.setState({
            successMessage:
                "You account was created and it's now pending approval.",
            accountCreated: true,
            submitButtonText: "Done!",
          });
        } else {
          if (registrationResult.errorStatus === 403) {
            this.setState({
              errorMessage:
                  "Registration failed. The email provided is already registered.",
              submitButtonText: "Register",
            });
          } else {
            console.log(registrationResult.errorStatus);
            this.setState({
              errorMessage: registrationResult.errorMessage,
              submitButtonText: "Register",
            });
          }
        }
      }
    else {
      this.setState({
        errorMessage:passwordIsValid,
        submitButtonText:"Register"
      });
    }
  }

  /**
   * Renders a Reigstration Page
   * @return {JSX.Element} the Registration Page
   */
  render() {
    return (
      <div className="ml-3 mr-4">
        <h3 className="mb-4">Create Account</h3>

        <form onSubmit={this.onSubmit}>
          <label className="font-weight-bold">Email</label>
          <input
            type="email"
            required
            className="form-control col-md-4"
            placeholder={this.state.email}
            value={this.state.email}
            onChange={this.onChangeEmail}
          />
          <label className="font-weight-bold">Username</label>
          <input
            type="text"
            required
            className="form-control col-md-4"
            placeholder={this.state.username}
            value={this.state.username}
            onChange={this.onChangeUsername}
          />
          <label className="font-weight-bold">Password</label>
          <input
            name="password"
            type="password"
            required
            className="form-control col-md-4"
            placeholder={this.state.password}
            value={this.state.password}
            onChange={this.onChangePassword}
          />
          <label className="font-weight-bold">Verify Password</label>
          <input
            name="verifyPassword"
            type="password"
            required
            className="form-control col-md-4"
            placeholder={this.state.verifyPassword}
            value={this.state.verifyPassword}
            onChange={this.onChangeVerifyPassword}
          />
          <br/>
          <input
              className={"btn btn-dark"}
              type={"submit"}
              disabled={this.state.accountCreated}
              value={this.state.submitButtonText}
          />
          <button
            className="btn btn-dark backButton"
            onClick={this.onClickLoginPage}
          >
            {"Back"}
          </button>
        </form>
        <label style={{ color: "blue", fontWeight: "bold" }}>
          {this.state.successMessage}
        </label>
        <label style={{ color: "red" }}>{this.state.errorMessage}</label>
      </div>
  );
  }
}
export default Registration
