import React, { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ConfirmDelete from "../Utils/confirmDelete.component";
import APIInterface from "./APIInterface";
import urlParser from "../Utils/parseIdsFromURL";
import { unauthorizedErrorMessage } from "../Utils/authorization";
import LoadingSpinner from "../Utils/LoadingSpinner";
import {Button, ButtonGroup} from "react-bootstrap";
import {ArrowDown, ArrowUp} from "react-bootstrap-icons";


/**
 * @class
 * The Content Page. Contains either [Phrase]{@link ContentPhrase} and/or [Text]{@link ContentText} pages
 */
class ContentPage extends Component {


  /**
   * @constructor
   * @param props inheritied from component, unused
   * @summary binds all of the necessary methods, uses the [URL Parser]{@link module:IDParser~ContentPageParser} module
   * to pull out the ID of the Content page to represent and assigns it as state
   */
  constructor(props) {
    super(props);

    this.onClickAdd = this.onClickAdd.bind(this);
    this.onChangeContentOrder = this.onChangeContentOrder.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.closeDeleteConfirmationModal = this.closeDeleteConfirmationModal.bind(
      this
    );

    let elementsId = urlParser.ContentPageParser(window.location.href);

    this.state = {
      pg_table_name: "",
      deleteitemIndex: "",
      content: [],
      contentpg_id: elementsId.contentPage_id,
      lesson_id: elementsId.lesson_id,
      showModal: false,
      errorMessage: "",
      saveButtonText: "Save Page",
      showLoading:true,
    };
  }

  /**
   * Builds content once mounted
   * @summary When the component mounts, we must first decide if we are going to show a new, blank content page
   * or if we are going to try to pull one from the database.
   * If So it [Fetches Existing Content]{@link module:ContentPage-API-Interface~getElement} and
   * [Existing Sub Content]{@link module:ContentPage-API-Interface~getContentTableNames} otherwise it
   *
   *
   * @return {Promise<void>}
   */
  async componentDidMount() {

    if(this.state.contentpg_id !== "newContent") {
      let contentPage = await APIInterface.getElement(this.state.contentpg_id);

      if (contentPage.errorStatus === undefined) {
        contentPage.content.sort(function (a, b) {
          return a.display_order - b.display_order;
        });

        const contentPages = await APIInterface.getContentTableNames(
            contentPage.content
        );

        if (contentPages.errorStatus === undefined) {
          this.setState({
            pg_table_name: contentPage.pg_table_name,
            content: contentPages,
            showLoading: false,
          });
        } else if (contentPages.errorStatus === 401) {
          window.location = "/login";
        } else {
          this.setState({
            errorMessage: contentPages.errorMessage,
          });
        }
      } else if (contentPage.errorStatus === 401) {
        window.location = "/login";
      } else {
        this.setState({
          errorMessage: contentPage.errorMessage,
        });
      }
    } else {
      this.setState({showLoading:false});
    }
  }

  /**
   * Handles the OnClick of the Add Content button.
   * @summary Saves the current configuration, then adds a new text or phrase page
   * to this content page, and navigates to it's definition
   * @param e
   * @param {"Text"|"Phrase"}e.target.value holds the type of the phrase to add
   * @return {Promise<void>}
   */
  onClickAdd = async (e) => {
    let type = e.target.value;

    let saveContentResponse = this.onSubmit("new").then(async () =>{

        if(this.state.contentpg_id === "newContent"){
          this.setState({contentpg_id:saveContentResponse});
        }

      if (saveContentResponse.errorStatus === undefined) {
        let nextPageRedirect =
            "/lesson/" +
            this.state.lesson_id +
            "/contentPage/" +
            this.state.contentpg_id;

        if (type === "Text") {
          nextPageRedirect = nextPageRedirect + "/text/newText" ;
        } else {
          nextPageRedirect = nextPageRedirect + "/phrase/newPhrase";
        }
        window.location = nextPageRedirect;

      } else {
        if (saveContentResponse.errorStatus === 401) {
          this.setState({
            errorMessage: unauthorizedErrorMessage,
            saveButtonText: "Save Page (Login first)",
          });
          window.open("/login", "_blank");
        } else {
          this.setState({
            errorMessage: saveContentResponse.errorMessage,
            saveButtonText: "Save Page",
          });
        }
      }
    });
  };

  /**
   * Event to handle a change in the order of Content Page sub items
   * @param e
   * @param {string} e.dir - the direction to move the item
   * @param {string} e.idx - the index of the item that the button was clicked on
   */
  onChangeContentOrder = (e) => {

    let updatedContentList = this.state.content; //the new list
    let swpIdx = parseInt(e.idx); //the idx of the one we clicked the button on
    let dir = e.dir; //The direction we need to move it
    let targetIdx = -1; //The index of who we are swapping with. SHOULD NOT STAY -1, THIS IS FOR CATCHING ERRORS

    //Setup our Swap
    if(swpIdx === 0 && dir === 'up')
      targetIdx = updatedContentList.length - 1;
    else if (swpIdx === updatedContentList.length-1 && dir === 'down')
      targetIdx = 0;
    else if (dir === 'up')
      targetIdx = swpIdx - 1;
    else if (dir === 'down')
      targetIdx = swpIdx + 1;
    else
      console.error(`SWPi:${swpIdx} TARi: ${targetIdx} wtf`);

    //Grab the elements from the original list
    let swp = this.state.content[swpIdx];
    let target = this.state.content[targetIdx];

    //Switch the position _fields_ on the items for edge cases
    if(targetIdx === 0 && swpIdx === this.state.content.length) {
      swpIdx = 1;
      targetIdx = this.state.content.length + 1
    }

    //todo something seems funky here. edge cases may behave strange with this double increment...
    target.display_order= swpIdx + 1;
    swp.display_order = targetIdx + 1;

    //swap the positions of the items themselves in the array
    updatedContentList[swpIdx] = target;
    updatedContentList[targetIdx] = swp;

    //Update the parent with the new list.
    this.setState({content: updatedContentList});
  }

  /**
   * Opens the selected sub-content page for editing
   * @summary [saves the current state]{@link module:ContentPage-API-Interface~saveContent},
   * then navigates to the selected sub-item for editing.
   * @param e
   * @return {Promise<void>}
   */
  onClickEdit = async (e) => {
    var nextPageRedirect =
      "/lesson/" +
      this.state.lesson_id +
      "/contentPage/" +
      this.state.contentpg_id;

    if (this.state.content[e.target.id].content_type === "Text") {
      nextPageRedirect = nextPageRedirect + "/text/" + e.target.value;
    } else if (this.state.content[e.target.id].content_type === "Phrase") {
      nextPageRedirect = nextPageRedirect + "/phrase/" + e.target.value;
    }

    const saveContentResponse = await APIInterface.saveContent({
      contentPage_id: this.state.contentpg_id,
      pg_table_name: this.state.pg_table_name,
      content: this.state.content,
    });

    if (saveContentResponse.errorStatus === undefined) {
      window.location = nextPageRedirect;
    } else {
      if (saveContentResponse.errorStatus === 401) {
        this.setState({
          errorMessage: unauthorizedErrorMessage,
          saveButtonText: "Save Page (Login first)",
        });
        window.open("/login", "_blank");
      } else {
        this.setState({
          errorMessage: saveContentResponse.errorMessage,
          saveButtonText: "Save Page",
        });
      }
    }
  };

  /**
   * Records the index of the content-sub item delete id
   * @param e
   * @param {string} e.target.value the id of the item to delete
   * @see ConfirmDelete
   */
  onClickDelete = (e) => {
    this.setState({
      showModal: true,
      deleteitemIndex: e.target.id,
    });
  };

  /**
   * Carries out the deletion of the contentPage sub-item with the given ID
   * @summary pulls the target ID out of state that was recorded [earlier]{@link #onClickDelete}
   * then [saves the content]{@link module:ContentPage-API-Interface~saveContent} before calling on the
   * API to [Delete]{@link module:ContentPage-API-Interface~deleteContent} the sub item.
   * @param {string} elementId the ID of the sub-element to delete from the contentPage/Database
   * @return {Promise<void>}
   * @see ConfirmDelete
   */
  onConfirmDelete = async (elementId) => {
    this.setState({
      showModal: false,
      deleteitemIndex: undefined,
    });

    const saveContentResponse = await APIInterface.saveContent({
      contentPage_id: this.state.contentpg_id,
      pg_table_name: this.state.pg_table_name,
      content: this.state.content,
    });

    if (saveContentResponse.errorStatus === undefined) {
      const deleteContentResponse = await APIInterface.deleteContent({
        content_type: this.state.content[elementId].content_type,
        content_id: this.state.content[elementId].content_id,
      });

      if (deleteContentResponse.errorStatus === undefined) {
        window.location =
          "/lesson/" +
          this.state.lesson_id +
          "/contentPage/" +
          this.state.contentpg_id;
      } else {
        if (deleteContentResponse.errorStatus === 401) {
          this.setState({
            errorMessage: unauthorizedErrorMessage,
            saveButtonText: "Save Page (Login first)",
          });
          window.open("/login", "_blank");
        } else {
          this.setState({
            errorMessage: deleteContentResponse.errorMessage,
            saveButtonText: "Save Page",
          });
        }
      }
    } else {
      if (saveContentResponse.errorStatus === 401) {
        this.setState({
          errorMessage: unauthorizedErrorMessage,
          saveButtonText: "Save Page (Login first)",
        });
        window.open("/login", "_blank");
      } else {
        this.setState({
          errorMessage: saveContentResponse.errorMessage,
          saveButtonText: "Save Page",
        });
      }
    }
  };

  /**
   * Closes the [Confirm Delete Modal]{@link ConfirmDelete}
   */
  closeDeleteConfirmationModal = () => {
    this.setState({
      showModal: false,
    });
  };

  /**
   * Essentially, this is means "Save" the current page state.
   * @summary If this is a new page, it is [added]{@link module:ContentPage-API-Interface~addContentPage}, otherwise it
   * [saves]{@link module:ContentPage-API-Interface~saveContent} the content as it is currently configured.
   * @param {string} redirect the url to redirect to after save. If it is a new page, it first calls add.
   * @return {Promise<*>}
   */
  async onSubmit(redirect) {

    let saveContentResponse;
    let contentPage = {
      contentPage_id: this.state.contentpg_id,
      pg_table_name: this.state.pg_table_name,
      content: this.state.content,
      lesson_id: this.state.lesson_id,
    }

    if(this.state.contentpg_id === "newContent"){
      saveContentResponse = await APIInterface.addContentPage(contentPage)
      this.setState({contentpg_id:saveContentResponse})
      if(redirect !== "new") {
        window.location = "/lesson/" + this.state.lesson_id + "/contentPage/" + this.state.contentpg_id;
      }
    } else {
      saveContentResponse = await APIInterface.saveContent(contentPage);
    }

    if (saveContentResponse.errorStatus === undefined) {
      console.log(`Content Page Successfully Saved`)
    } else {
      if (saveContentResponse.errorStatus === 401) {
        this.setState({
          errorMessage: unauthorizedErrorMessage,
          saveButtonText: "Save Page (Login first)",
        });
        window.open("/login", "_blank");
      } else {
        this.setState({
          errorMessage: saveContentResponse.errorMessage,
          saveButtonText: "Save Page",
        });
      }
    }

   return saveContentResponse;
  }

  /**
   * Renders a Content Page
   * @return {JSX.Element} The Content Page
   * @see LoadingSpinner
   * @see ConfirmDelete
   */
  render() {
    return (
      <div className="ml-3 mr-4">
        {this.state.showLoading &&
            <LoadingSpinner top={'50%'} left={'50%'}/>
        }

          {!this.state.showLoading &&
          <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href={`/lesson/${this.state.lesson_id}`}>
              Lesson
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Content Page</Breadcrumb.Item>
          </Breadcrumb>
          <h3 className="mb-4">{this.state.pg_table_name || "New Content Page"}</h3>
          <div className="form-group">
            <button
              className="btn btn-outline-dark"
              value={"Text"}
              onClick={this.onClickAdd}
            >
              Add Text/Story
            </button>
            <button
              className="btn btn-outline-dark ml-3"
              value={"Phrase"}
              onClick={this.onClickAdd}
            >
              Add Phrase
            </button>
          </div>
            {this.state.content.length > 0 &&
            <table className="table table-striped table-hover table-bordered mt-4 mb-4">
              <thead className="thead-dark">
              <tr>
                <th scope="col" width="130">
                  Display Order
                </th>
                <th scope="col">Name</th>
                <th scope="col" width="200">
                  Type
                </th>
                <th scope="col" width="200">
                  Options
                </th>
              </tr>
              </thead>
              <tbody>
              {this.state.content.map((val, idx) => {
                return (
                    <tr key={idx}>
                      <td>
                        <ButtonGroup>
                          <Button className="btn-dark mb-2"
                                  size={"sm"}
                                  onClick={() => this.onChangeContentOrder({idx:idx,dir:'up'})}>
                            <ArrowUp className="btn-dark"/>
                          </Button>
                        </ButtonGroup>

                        <ButtonGroup>
                          <Button className="btn-dark mb-2"
                                  size={"sm"}
                                  onClick={() => this.onChangeContentOrder({idx:idx,dir:'down'})}>
                            <ArrowDown className="btn-dark"/>
                          </Button>
                        </ButtonGroup>
                      </td>
                      <td onClick={() => this.onClickEdit({
                        target: {
                          id: idx,
                          value: val.content_id
                        }
                      })}>{val.cnt_table_name}</td>
                      <td onClick={() => this.onClickEdit({target: {id: idx, value: val.content_id}})}>
                        {val.content_type === "Text"
                            ? "Text/Story"
                            : val.content_type}
                      </td>
                      <td>
                        <button
                            className="btn btn-outline-dark"
                            id={idx}
                            value={val.content_id}
                            onClick={this.onClickEdit}
                        >
                          Edit
                        </button>
                        <button
                            className="btn btn-outline-dark ml-3 "
                            id={idx}
                            value={val.content_id}
                            onClick={this.onClickDelete}
                        >
                          Delete
                        </button>
                        <ConfirmDelete
                            id={this.state.deleteitemIndex}
                            show={this.state.showModal}
                            closeModalCallback={this.closeDeleteConfirmationModal}
                            deleteFunction={this.onConfirmDelete}
                        />
                      </td>
                    </tr>
                );
              })}
              </tbody>
            </table>
            }
        <div className="form-group ">
          <label className="font-weight-bold">
            Name (to be displayed on the lesson page table)
          </label>
          <input
            type="text"
            required
            className="form-control col-md-4"
            placeholder={this.state.pg_table_name}
            value={this.state.pg_table_name}
            onChange={e => { this.setState({pg_table_name:e.target.value})} }
          />
        </div>
        <div className="form-group">
          <button className="btn btn-dark" onClick={this.onSubmit}>
            {this.state.saveButtonText}
          </button>
        </div>
        <label style={{ color: "red" }}>{this.state.errorMessage}</label>
      </div>}
      </div>
    );
  }
}

export default ContentPage
