/**
 * @module Phrase-API-Interface
 */

import axios from "axios";
import {getAuthorizationHeadersObject} from "../../Utils/authorization";

/**
 * Retrieves a phrase element from the database
 * @param {string}elementId the id of the phrase
 * @return {Promise<any>} the response data (A JSON representation of a phrase)
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getElement(elementId) {
  let getPhraseURL = process.env.REACT_APP_BACKEND_PHRASE_CNT_URI + elementId;

  let phraseAPIResponse;
  try {
    phraseAPIResponse = await axios.request({
      method: "GET",
      url: getPhraseURL,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, refresh your page.",
    };
  }

  return phraseAPIResponse.data;
}

/**
 * Builds a phrase payload for the API
 * @param phraseObject the complete phrase definition
 * @return {FormData} the data relevant to phrase requests as form data
 */
function updatePhraseRequestObject(phraseObject) {
  const data = new FormData();
  data.append("audio", phraseObject.audioFile);
  data.append("image", phraseObject.imageFile);
  data.append("cnt_table_name", phraseObject.cnt_table_name);
  data.append("morphemes", JSON.stringify(phraseObject.morphemes));
  return data;
}


/**
 * Adds a new phrase to the database
 * @param props the full state of a phrase
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function addElement(props){
  let addPhraseUrl = process.env.REACT_APP_BACKEND_PHRASE_CNT_URI + "add/";

  let phraseBody = {
    cnt_page_id:props.cnt_page_id,
    cnt_table_name:props.cnt_table_name,
    morphemes:props.morphemes,
  }

  try{
    return (await axios.post(
        addPhraseUrl,
        phraseBody,
        getAuthorizationHeadersObject()
    )).data
  } catch (error){
    console.error(`ERROR ADDING NEW PHRASE ${error}`);
    return {
      errorStatus: error.response.status,
      errorMessage: error.response.body
    }
  }
}

/**
 * Updates an existing phrase in the database
 * @param props the full definition of a phrase object to push to the database
 * @see module:Authorization~getAuthorizationHeadersObject
 * @see #updatePhraseRequestObject
 * @return {Promise<any>}
 */
async function updateElement(props) {
  let updatePhraseURL =
    process.env.REACT_APP_BACKEND_PHRASE_CNT_URI +
    "update/" +
    props.fullElement.phrase_id;
  let requestBody = updatePhraseRequestObject(props.fullElement);
  try {
    let updateResponse = await axios.post(
      updatePhraseURL,
      requestBody,
      getAuthorizationHeadersObject()
    );
    return updateResponse;
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, try again.",
    };
  }
}

/**
 * Gets a URL for a phrase's Audio
 * @function getAudioFileURL
 * @param props
 * @param {string} props.element_id the ID of the phrase to get audio for
 * @return {string} a resource url for the phrase's audio
 */
function getAudioFileURL(props) {
  return (
    process.env.REACT_APP_BACKEND_PHRASE_CNT_URI +
    "media/audio/" +
    props.element_id
  );
}

/**
 * Gets a URL for a phrase's Image
 * @function getImageFileURL
 * @param props
 * @param {string} props.element_id the ID of the phrase to get the image for
 * @return {string} a resource url for the phrase's image
 */
function getImageFileURL(props) {
  return (
    process.env.REACT_APP_BACKEND_PHRASE_CNT_URI +
    "media/image/" +
    props.element_id
  );
}

/**
 * Wraps The Call to download audio to the calling machine
 * @param props
 * @return {Promise<any>} the file download
 */
async function downloadAudio(props) {
  return await downloadMedia(props, "Audio");
}

/**
 * Wraps The Call to download an image to the host machine
 * @param props
 * @return {Promise<any>} the file download
 */
async function downloadImage(props) {
  return await downloadMedia(props, "Image");
}

/**
 * Preforms the call to get audio or image from the database
 * @summary takes in the type then uses the getter either the [Audio URL]{@link module:Phrase-API-Interface~getAudioFileURL}
 * or [Image URL]{@link module:Phrase-API-Interface~getImageFileURL} to request media from the database
 * @param props
 * @param {"Audio"|"Image"} mediaType the type of media to call for the download of
 * @return {Promise<any>} the file download
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function downloadMedia(props, mediaType) {
  let downloadAudioURL = "";
  if (mediaType === "Audio") {
    downloadAudioURL = getAudioFileURL(props);
  } else if (mediaType === "Image") {
    downloadAudioURL = getImageFileURL(props);
  }

  try {
    let fileResponse = await axios.request({
      method: "GET",
      url: downloadAudioURL,
      headers: getAuthorizationHeadersObject().headers,
      responseType: "blob",
    });
    return fileResponse;
  } catch (error) {
    return {
      errorStatus: error.response.status,
    };
  }
}

/**
 * Wraps the call to delete audio configured for a phrase
 * @param props
 * @return {Promise<any>}
 */
async function deleteAudio(props) {
  return await deleteMedia(props, "Audio");
}

/**
 * Wraps the call to delete an Image configured for a phrase
 * @param props
 * @return {Promise<any>}
 */
async function deleteImage(props) {
  return await deleteMedia(props, "Image");
}

/**
 * The Call to delete media from the server for the passed phrase
 * @summary takes in the type then uses either the [Audio URL]{@link module:Phrase-API-Interface~getAudioFileURL}
 * or [Image URL]{@link module:Phrase-API-Interface~getImageFileURL} to request deletion of media from the database
 * @param props
 * @param {"Audio"|"Image"} mediaType the type of the media to call for the deletion of
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function deleteMedia(props, mediaType) {
  let deleteAudioURL = "";
  if (mediaType === "Audio") {
    deleteAudioURL = getAudioFileURL(props);
  } else if (mediaType === "Image") {
    deleteAudioURL = getImageFileURL(props);
  }

  try {
    let deleteResponse = await axios.request({
      method: "DELETE",
      url: deleteAudioURL,
      headers: getAuthorizationHeadersObject().headers,
    });
    return deleteResponse;
  } catch (error) {
    return {
      errorStatus: error.response.status,
    };
  }
}

const phraseAPIInterface = {
  downloadAudio,
  downloadImage,
  deleteAudio,
  deleteImage,
  addElement,
  updateElement,
  getElement,
};

export default phraseAPIInterface;
