import React, { Component } from "react";
/**
 * @module MediaSectionComponent
 */

/**
 * @typedef MediaDetails
 * @property {string} file_id the ID of the file in the database
 * @property {string} file_path the path to the file as known by the database
 * @property {string} file_name the name of the file
 */

/**
 * File Management Elements
 * @see UploadFileSection
 * @see ShowCurrentFile
 * @see FileOptions
 */
import {
  UploadFileSection,
  ShowCurrentFile,
  FileOptions,
} from "./uploadMediaSections.component";
import download from "downloadjs";

/**
 * @see unauthorizedErrorMessage
 */
import { unauthorizedErrorMessage } from "../authorization";

/**
 * A collection of blob keys and url values
 * @type {WeakMap<object, any>}
 */
let previewUrls = new WeakMap();

/**
 * Looks in a cache of blobs for the appropriate blob url, adds it to the cache if not present,
 * and returns the media url
 * @function blobUrl
 * @param {Blob} blob  a blob text representing some media
 * @return {string|any} the blob url for the passed blob media
 */
let blobUrl = (blob) => {
  if (previewUrls.has(blob)) {
    return previewUrls.get(blob);
  } else {
    let url = URL.createObjectURL(blob);
    previewUrls.set(blob, url);
    return url;
  }
};

/**
 * Checks if the passed file has an extension matching an image
 * @function isImageFile
 * @param {string} fileExtension the extension of the file
 * @return {boolean|boolean} True if the extension matches a list of supported types
 */
const isImageFile = (fileExtension) => {
  const supportedImageTypes = ["jpeg", "png", "jpg", "image/png", "image/jpeg"];
  return supportedImageTypes.includes(fileExtension)
};

/**
 * Checks if the passed file has an extension matching audio
 * @param {string} fileExtension the extension of the file
 * @return {boolean|boolean} true if the extension matches against a list of supported types.
 *
 */
const isAudioFile = (fileExtension) => {
  const supportedAudioTypes = ["mp3",'audio/mpeg','m4a',"audio/x-m4a","audio/m4a"];
  return supportedAudioTypes.includes(fileExtension.toLowerCase());
};


const imagePreviewStyle = {
  maxHeight: "200px",
  maxWidth: "300px",
  marginTop: "10px",
};

/**
 * @class
 * A DOM element that displays and manages Media
 */
class MediaSection extends Component {
  /**
   * @constructor
   * @param props
   * @param {function} props.onChangeFile the callback for when the configured file is changed
   * @param {function} props.downloadMediaAPICall the callback to download the configured media to the calling machine
   * @param {function} props.deleteMediaAPICall the callback to remove the configured from the Parent elements DBO
   * @param {boolean} props.reloadPageAfterDelete true/false do we reload after calling delete (may not be used)
   * @param {string} props.title the Title of this media section (Usually "Audio" or "Image")
   * @param {"audio"|"image"} props.mediaType the type of media to expect
   */
  constructor(props) {
    super(props);

    this.onChangeFile = this.onChangeFile.bind(this);
    this.onClickDownloadFile = this.onClickDownloadFile.bind(this);
    this.onClickDeleteFile = this.onClickDeleteFile.bind(this);
    this.getMimeTypeFromFileName = this.getMimeTypeFromFileName.bind(this);
    this.loadImagePreview = this.loadImagePreview.bind(this);

    this.state = {
      fileErrorFeedbackMessage: "",
      showUploadNewFile: false,
      downloadFileButtonText: "Download",
      deleteFileButtonText: "Delete",
      imageFilePreview: null,
      audioFilePreview: null,
      isLoadingImagePreview: false,
      isLoadingAudioPreview: false,
      audioPreviewOriginalFileName: "",
      audioPreviewMimeType: "",
      imagePreviewOriginalFileName: "",
      imagePreviewMimeType: "",
      dimensionsChecked: true
    };
  }

  /**
   * Handles the swapping out of the media file configured for a MediaSection holder
   * @summary Checks the validity and type of a media file, creates a preview, and updates the Parent's DBO to contain the new file once one has been
   * configured
   * @param e
   * @param {any[]} e.target.files
   * @see module:MediaManagementElements~UploadFileSection
   */
  onChangeFile = (e) => {
    this.setState({ showUploadNewFile: true, fileErrorFeedbackMessage: "" ,dimensionsChecked:false});
    if (e.target.files[0] === undefined) return;

    //First we check that the file is small enough
    if (e.target.files[0].size <= 10000000) {

      //Then we grab the extension...
      let fileExtension = e.target.files[0].name
          .split(".")
          .pop()
          .toLowerCase();

      //And check it against the media we are representing
      let isAudio = this.props.mediaType === "audio" && isAudioFile(fileExtension)
      let isImage = this.props.mediaType === "image" && isImageFile(fileExtension)

      //If it is a media we can represent...
      if (isAudio || isImage) {
        //Create a new media state to use
        let newMediaObjectState = {
          mediaFile: e.target.files[0],
          media: {
            file_id: undefined,
            file_path: "",
            file_name: e.target.files[0].name,
          },
          fileErrorFeedbackMessage: "",
        };

        if (isImage) {
          //If it is a new image, set the image preview
          this.setState({
            imageFilePreview: newMediaObjectState.mediaFile,
          });
        } else if (isAudio){
          //if it is audio, set an audio preview
          this.setState({
            audioFilePreview: newMediaObjectState.mediaFile
          })
        }

        if (this.props.children !== undefined) {
          newMediaObjectState.media.file_id = this.props.children.file_id;
          newMediaObjectState.media.file_path = this.props.children.file_path;
        }

        //and update the parent
        this.props.onChangeFile(newMediaObjectState);
      } else {
        //If we can't deal with this file type - alert of that
        this.setState({
          fileErrorFeedbackMessage: "Error: File type not allowed.",
        });
      }
    } else {
      //If we can't deal with file size - alert of that
      this.setState({
        fileErrorFeedbackMessage:
          "Error: The file is too big (maximum size allowed: 10MB).",
      });
    }
  };

  /**
   * Checks if a file has been uploaded to the Parents DBO
   * @param {MediaDetails} mediaObj
   * @param mediaObj.file_id the ID of the file in the Database
   * @return {boolean} true if there is a file_id configured for the represented Parent DBO
   */
  hasAFileUploaded(mediaObj) {
    if (mediaObj === undefined) return false;
    return mediaObj.file_id !== undefined;
  }

  /**
   * Takes in a file and returns the appropriate mime-type based on the extension
   * @param {string} file_name the name of a file
   * @return {string} the appropriate mime-type for the type of the passed file
   */
  getMimeTypeFromFileName(file_name) {
    let mimeType = 'none';
    let fileExtension = file_name
        .split(".")
        .pop()
        .toLowerCase();
    if (fileExtension === "mp3") {
      mimeType = "audio/mpeg";
    } else if (fileExtension === "m4a") {
      mimeType = "audio/m4a";
    } else if (fileExtension === "png") {
      mimeType = "image/png";
    } else if (fileExtension === "jpg" || fileExtension === "jpeg") {
      mimeType = "image/jpeg";
    }
    return mimeType;
  }

  /**
   * Loads a preview of the Parent's configured audio file
   * @summary makes an API call to download audio, gets its mime-type, creates a blob, and shoves it into our
   * state to be accessed on render
   * @return {Promise<void>}
   *
   * @see #getMimeTypeFromFileName
   */
  async loadAudioPreview(){
    let originalFileName = this.props.children.file_name;
    let downloadMimeType = this.getMimeTypeFromFileName(originalFileName);

    this.setState({
      downloadFileButtonText: "Loading...",
      fileErrorFeedbackMessage: "",
      isLoadingAudioPreview: true,
      audioPreviewOriginalFileName: originalFileName,
      audioPreviewMimeType: downloadMimeType,
    });

    let response = await this.props.downloadMediaAPICall();

    if (response.errorStatus === undefined) {
      let downloadMimeType = this.getMimeTypeFromFileName(originalFileName);
      const media = new Blob([response.data], { type: downloadMimeType });

      this.setState({
        audioFilePreview: media,
      });
    } else {
      if (response.errorStatus === 401) {
        this.setState({
          fileErrorFeedbackMessage: unauthorizedErrorMessage,
        });
        window.open("/login", "_blank");
      } else {
        console.error(`Failed to download Audio: ${JSON.stringify(response.errorStatus)}`)
        this.setState({
          fileErrorFeedbackMessage:
              "Error: Download failed. Please, refresh the page and try again.",
        });
      }
    }
    this.setState({
      downloadFileButtonText: "Download",
    });
  }

  /**
   * Loads a preview of the Parent's configured image file
   * @summary makes an API call to download image, gets its mime-type, creates a blob, and shoves it into our
   * state to be accessed on render
   * @return {Promise<void>}
   *
   * @see #getMimeTypeFromFileName
   */
  async loadImagePreview() {
    let originalFileName = this.props.children.file_name;
    let downloadMimeType = this.getMimeTypeFromFileName(originalFileName);

    this.setState({
      downloadFileButtonText: "Loading...",
      fileErrorFeedbackMessage: "",
      isLoadingImagePreview: true,
      imagePreviewOriginalFileName: originalFileName,
      imagePreviewMimeType: downloadMimeType,
    });

    let response = await this.props.downloadMediaAPICall();

    if (response.errorStatus === undefined) {
      let downloadMimeType = this.getMimeTypeFromFileName(originalFileName);
      const media = new Blob([response.data], { type: downloadMimeType });

      this.setState({
        imageFilePreview: media,
      });
    } else {
      if (response.errorStatus === 401) {
        this.setState({
          fileErrorFeedbackMessage: unauthorizedErrorMessage,
        });
        window.open("/login", "_blank");
      } else {
        this.setState({
          fileErrorFeedbackMessage:
            "Error: Download failed. Please, refresh the page and try again.",
        });
      }
    }

    this.setState({
      downloadFileButtonText: "Download",
    });
  }

  /**
   * The callback to download the MediaSection's file to calling machine
   * @summary Checks to see which file it has, audio or image, then calls on the download module to handle the rest
   * @return {Promise<void>}
   * @see #getMimeTypeFromFileName
   */
  onClickDownloadFile = async () => {
    //Download audio
    let mimeType = this.getMimeTypeFromFileName(this.props.children.file_name)
    if (this.state.audioFilePreview !== null && mimeType.includes("audio")) {
      download(
          this.state.audioFilePreview,
          this.state.audioPreviewOriginalFileName,
          this.state.audioPreviewMimeType
      );
    }
    //Download image
    else if(this.state.imageFilePreview !== null && mimeType.includes("image")) {
      download(
        this.state.imageFilePreview,
        this.state.imagePreviewOriginalFileName,
        this.state.imagePreviewMimeType
      );
    } else {
      console.error("UNSUPPORTED FILE TYPE:" + mimeType);
    }
  }

  /**
   * The Callback to delete media from the DBO that the Media Section'sparent is representing
   * @summary Uses the delete API call to remove audio from the represented media holder
   * @return {Promise<void>}
   */
  async onClickDeleteFile() {
    this.setState({
      deleteFileButtonText: "Deleting...",
      fileErrorFeedbackMessage: "",
    });
    let response = await this.props.deleteMediaAPICall();

    if (response.errorStatus === undefined) {
      if (this.props.reloadPageAfterDelete === false) return;

      window.location.reload(false);
    } else {
      if (response.errorStatus === 401) {
        this.setState({
          fileErrorFeedbackMessage: unauthorizedErrorMessage,
        });
        window.open("/login", "_blank");
      } else {
        this.setState({
          fileErrorFeedbackMessage:
            "Error: Couldn't delete file. Please, refresh the page and try again.",
        });
      }
    }

    this.setState({
      deleteFileButtonText: "Delete",
    });
  }

  /**
   * Validates the dimensions of new images
   * @summary when a new image is uploaded, the dimensions of this must be policed in order for them to show up nicely
   * in the learner app. This checks the validity of image dimensions, and alerts if they are out of scope [200,500]px
   */
  checkImageDimensions(){

    //Have we checked the dimensions of the current photo?
    if(this.state.dimensionsChecked === false) {
      //If not, let's get the url
      let url = this.state.imageFilePreview && blobUrl(this.state.imageFilePreview);
      //Make a fake-o image so that we can inspect its properties
      let testImg = new Image();
      testImg.src = url;
      testImg.hidden = true;

      //Preform the check
      testImg.onload = () => {
        let validHeight = testImg.height >= 200 && testImg.height <= 500
        let validWidth = testImg.width >= 200 && testImg.width <= 500
        let validDimensions = validHeight && validWidth;

        //Notify if check was preformed
        this.setState({ dimensionsChecked: true });

        //If the dimensions are invalid, alert and disallow the upload.
        if(validDimensions === false) {
          alert("Invalid image dimensions. Please Set between 500px and 200px");
          this.setState({imageFilePreview:null});
          window.location.reload();
        }
      }
    }
  }

  /**
   * Renders a new Media Section complete with
   * [file display]{@link module:MediaManagementElements~ShowCurrentFile},
   * [file management options]{@link module:MediaManagementElements~FileOptions}, and
   * [upload functionality]{@link module:MediaManagementElements~UploadFileSection}.
   * @return {JSX.Element}
   * @see #loadImagePreview
   * @see #loadAudioPreview
   *
   * @return {JSX.Element} a MediaSection Component
   */
  render() {
    const isMediaObjectProvided = this.props.children !== undefined;
    let loadImagePreview = false;
    let loadAudioPreview = false;

    if (isMediaObjectProvided) {
      this.checkImageDimensions();
      let fileMimeType = this.getMimeTypeFromFileName(
        this.props.children.file_name
      );
      loadImagePreview = isImageFile(fileMimeType);
      loadAudioPreview = isAudioFile(fileMimeType);
    }

    //Setting up the image preview
    let { imageFilePreview, isLoadingImagePreview } = this.state;
    let imagePreviewUrl = imageFilePreview && blobUrl(imageFilePreview);

    let { audioFilePreview, isLoadingAudioPreview } = this.state;
    let audioPreviewUrl = audioFilePreview && blobUrl(audioFilePreview);

    if (isMediaObjectProvided) {
      if (imageFilePreview === null && isLoadingImagePreview === false && loadImagePreview) {
        this.loadImagePreview().catch(err => {console.error(`Failed to get blob For Image: ${err}`)});
      } else if (audioFilePreview === null && isLoadingAudioPreview === false && loadAudioPreview) {
        this.loadAudioPreview().catch(err => {console.error(`FAILED TO GET BLOB FOR AUDIO: ${err}`)});
      }
    }
    return (
      <>
        <div className="form-group col-md-6">
          <label className="font-weight-bold">{this.props.title} </label>
          <ShowCurrentFile>{this.props.children}</ShowCurrentFile>
          <FileOptions
            onClickDownload={this.onClickDownloadFile}
            downloadButtonText={this.state.downloadFileButtonText}
            onclickUploadFile={() => this.setState({showUploadNewFile:true})}
            uploadFileButtonText="Replace"
            onClickDeleteFile={this.onClickDeleteFile}
            deleteButtonText={this.state.deleteFileButtonText}
            showOptions={this.hasAFileUploaded(this.props.children)}
          >
            {this.props.children}
          </FileOptions>
          <UploadFileSection
            onChangeFunction={this.onChangeFile}
            hide={
              !isMediaObjectProvided ? false : !this.state.showUploadNewFile
            }
          />
          {imagePreviewUrl && (
            <img
              style={imagePreviewStyle}
              src={imagePreviewUrl}
              alt="Preview"
            />
          )}
          {audioPreviewUrl && (
              <audio
                  src={audioPreviewUrl}
                  controls={true}
              />
          )}

          <label style={{ color: "red" }}>
            {this.state.fileErrorFeedbackMessage}
          </label>
        </div>
      </>
    );
  }
}

export default MediaSection