import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Navbar from "./components/navbar.component";
import HomePage from "./components/Home/home-page.component";
import ContactPage from "./components/Home/Contact/contact-page.component";
import Lesson from "./components/Lesson/lesson.component";
import ContentPage from "./components/ContentPage/content-page.component";
import TextContent from "./components/ContentPage/Text/content-text.component";
import PhraseContent from "./components/ContentPage/Phrase/content-phrase.component";
import Login from "./components/Login/login.component";
import Registration from "./components/Registration/registration.component";
import RequestsManagement from "./components/UserManagementPages/ManageRequests/requestsManagement.component";
import UsersManagement from "./components/UserManagementPages/ManageUsers/usersManagement.component";
import LanguageManagement from "./components/UserManagementPages/LanguageManagement/LanguageManagement";
import LanguageDefinition from "./components/UserManagementPages/LanguageManagement/LanguageDefinition";
import MCQuestion from "./components/MultipleChoiceQuestion/MC_Question/mcQuestion.component";
import WSQuestion from "./components/MultipleChoiceQuestion/WS_Question/WSQuestion.component";

function App() {
  return (
    <Router>
      <Navbar isAuthenticated={localStorage.getItem("access_token")} role={localStorage.getItem("user_role")}/>
        <br/>
      <Routes>
      <Route path="/login" exact element={<Login/>} />
      <Route path="/register" exact element={<Registration/>} />
      <Route path="/requestsManagement" exact element={<RequestsManagement/>} />
      <Route path="/languageManagement" exact element={<LanguageManagement/>} />
      <Route path="/languageManagement/:id" exact element={<LanguageDefinition/>} />
      <Route path="/usersManagement" exact element={<UsersManagement/>} />
      <Route path="/" exact element={<HomePage/>} />
        <Route path="/contact" exact element={<ContactPage/>} />
      <Route path="/lesson/:id" exact element={<Lesson/>} />
      <Route
        path="/lesson/:idLesson/contentPage/:idCP"
        exact
        element={<ContentPage/>}
      />
      <Route
        path="/lesson/:idLesson/mcQuestion/:idQ"
        exact
        element={<MCQuestion/>}
      />
      <Route
          path="/lesson/:idLesson/wsQuestion/:idQ"
          exact
          element={<WSQuestion/>}
      />
      <Route
        path="/lesson/:idLesson/contentPage/:idCP/text/:idText"
        exact
        element={<TextContent/>}
      />
      <Route
        path="/lesson/:idLesson/contentPage/:idCP/phrase/:idPhrase"
        exact
        element={<PhraseContent/>}
      />
      </Routes>
    </Router>
  );
}

export default App;
