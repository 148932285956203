import React, {Component} from "react";
import APIInterface from "./APIInterface";
import MediaSection from "../Utils/Media/media.component";

/**
 * @class
 * The Instructions sub-component of a [MCQuestion]{@link MCQuestion}.
 */
class Instructions extends Component {

  /**
   * @constructor
   * @param props
   * @param {function} props.updateStateFunction updates the parent elements state (see [updateInstructions]{@link MCQuestion#updateInstructions})
   * @param {function} props.saveFunction a function to save changes to the database
   * @param {string} props.element_id the ID of the question in the database
   */
  constructor(props) {
    super(props);

    this.onChangeCreeInstructions = this.onChangeCreeInstructions.bind(this);
    this.onChangeEngInstructions = this.onChangeEngInstructions.bind(this);
    this.onChangeAudio = this.onChangeAudio.bind(this);
    this.downloadAudio = this.downloadAudio.bind(this);
    this.deleteAudio = this.deleteAudio.bind(this);
  }

  /**
   * Handles the change of the taught language instructions for the quiz
   * @summary uses the [Update State]{@link MCQuestion#updateInstructions} function to set the taught language
   * instructions to the passed value in the database.
   * @param e
   * @param {string} e.target.value The updated text for the taught language text
   */
  onChangeCreeInstructions = (e) => {
    this.props.updateStateFunction({
      text_cree: e.target.value,
      text_eng: this.props.children.text_eng,
      audio: this.props.children.audio,
    });
  };

  /** Handles the change of the English instructions for the quiz
  * @summary uses the [Update State]{@link MCQuestion#updateInstructions} function to set the english
  * instructions to the passed value in the database.
  * @param e
  * @param {string} e.target.value The updated text for the english text
  */
  onChangeEngInstructions = (e) => {
    this.props.updateStateFunction({
      text_eng: e.target.value,
      text_cree: this.props.children.text_cree,
      audio: this.props.children.audio,
    });
  };

  /** Handles the change of the Audio for the quiz
   * @summary uses the [Update State]{@link MCQuestion#updateInstructions} function to set the audio configured
   * for the quiz to the passed definition
   * @param audioObject the updated audio definition
   */
  onChangeAudio = (audioObject) => {
    this.props.updateStateFunction(
      {
        text_eng: this.props.children.text_eng,
        text_cree: this.props.children.text_cree,
      },
      audioObject,
    );
  };

  /**
   * Calls for the download of audio configured for this question
   * @see [downloadAudio]{@link module:Question-API-Interface~downloadAudio}
   * @return {Promise<*>}
   */
  async downloadAudio() {
    return await APIInterface.downloadAudio({
      question_id: this.props.elementId,
    });
  }


  /**
   * Calls for the deletion of audio configured for this question
  * @see [deleteAudio]{@link module:Question-API-Interface~deleteAudio}
  */
  async deleteAudio() {
    return await APIInterface.deleteAudio({
      question_id: this.props.elementId,
    });
  }

  /**
   * Renders an Instructions section for a question
   * @return {JSX.Element} the Instructions sub-component
   * @see MediaSection
   */
  render() {
    return (
      <div>
        <h5 className="font-weight-bold">Instructions:</h5>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label className="font-weight-bold">Indigenous Language</label>
            <textarea
              type="text"
              rows="6"
              required
              className="form-control"
              placeholder={this.props.children.text_cree}
              value={this.props.children.text_cree}
              onChange={this.onChangeCreeInstructions}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="font-weight-bold">English</label>
            <textarea
              type="text"
              rows="6"
              required
              className="form-control"
              placeholder={this.props.children.text_eng}
              value={this.props.children.text_eng}
              onChange={this.onChangeEngInstructions}
            />
          </div>
        </div>

        {this.props.elementId === "newQuestion" &&
            <button
                className="btn btn-dark"
                onClick={this.props.saveFunction}
                > Add Audio </button>
        }
        {this.props.elementId !== "newQuestion" &&
        <MediaSection
            onChangeFile={this.onChangeAudio}
            downloadMediaAPICall={this.downloadAudio}
            deleteMediaAPICall={this.deleteAudio}
            reloadPageAfterDelete={true}
            title="Audio"
            mediaType={"audio"}>
          {this.props.children.audio}
        </MediaSection>
        }
      </div>
    );
  }
}
export default Instructions;
