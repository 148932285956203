import React from "react";
import { TransparentButton } from "../Buttons";

/**
 * tools to aid in CRUD media operations
 * @module MediaManagementElements
 */

/**
 * The DOM element to Upload a file
 * @function UploadFileSection
 * @param props
 * @param {function} props.onChangeFunction a function to call when the file has changed
 * @param {boolean} props.hide true/false hide this element
 * @return {JSX.Element} The DOM component to upload a file
 * @return {JSX.Element} empty element if "props.hide" is true
 */
export const UploadFileSection = (props) => {
  if (props.hide) return <></>;

  return (
    <input
      type="file"
      name="file"
      className="form-control-file"
      onChange={props.onChangeFunction}
    />
  );
};

/**
 * Returns a preview of a file's name
 * @function ShowCurrentFile
 * @param props holds the file currently configured for the parent
 * @param {string} props.children.file_name the name of the file configured for the parent
 * @return {JSX.Element} a display of the name of the current  file
 * @todo consider if this is even really needed... It's so small, seems like it could just be added to the parent element's definition
 */
export const ShowCurrentFile = (props) => {
  if (props.children === undefined) return <></>;

  return (
    <p>
      Current file: <i>{props.children.file_name}</i>
    </p>
  );
};

/**
 * A Dom element that presents file Operations
 * @function FileOptions
 * @param props
 * @param props.children
 * @param {boolean} props.showOptions true/false should we display file options?
 * @param {function} props.onClickDeleteFile the function to call to delete a file from a DBO
 * @param {string} props.deleteButtonText the label of the delete button
 * @param {function} props.onclickUploadFile the function to call to upload a file to a DBO
 * @param {string} props.uploadFileButtonText the label of the upload button
 * @param {function} props.onClickDownload the function to call to download DBO audio to the calling machine
 * @param {string} props.downloadButtonText the label of the download button
 *
 * @return {JSX.Element} A DOM element that allows for file operations
 * @return {JSX.Element} an empty element if no children or showOptions set to false
 * @todo Evaluate if we really even need the labels. Do they ever change? also, consider removing show options and instead implement display logic in parent class
 *
 */
export const FileOptions = (props) => {
  if (props.children === undefined) return <></>;
  if (props.showOptions === false) return <></>;

  return (
    <div className="form-row ">
      <TransparentButton onClick={props.onClickDownload}>
        {props.downloadButtonText}
      </TransparentButton>
      <TransparentButton onClick={props.onclickUploadFile}>
        {props.uploadFileButtonText}
      </TransparentButton>
      <TransparentButton onClick={props.onClickDeleteFile}>
        {props.deleteButtonText}
      </TransparentButton>
    </div>
  );
};
