import axios from "axios";
import {getAuthorizationHeadersObject} from "../Utils/authorization";

/**
 * @module Question-API-Interface
 */

/**
 * @typedef Question
 * @property {string} question_id THe ID of this question
 * @property {string} lesson_id The ID of this question's parent lesson
 * @property {string} pg_table_name a quiz's parent page table
 * @property {string} author for the quiz's author
 * @property {QuizInstructions[]} instructions Mongoose Schema Object for the quiz's text instructions
 * @property {QuizOption[]} options a list of QuizOptions that serve as the options for this quiz
 * @property {Morpheme[]} morphemes a list of morphemes to reorder for this quiz.
 * @property {string} __t the type of quiz that this is.
 * @property {string} lesson_id the id of the lesson this quiz belongs to
 * @property {any} audioFile An object containing audio details  for a question
 */

/**
 * The instructions component of a question
 * @typedef QuizInstructions
 * @property {string} text_eng the quiz instruction's english text
 * @property {string} text_cree the quiz instruction's english text
 * @property {any} audio details for the question's audio
 */

/**
 * An option of a question
  * @typedef  QuizOption
  * @property {string} option_id the options ID in the database
 * @property {string} question_id the ID of the option's parent question
 * @property {string} text_eng an option's english text
  * @property {string} text_cree option's indigenous text
  * @property {any} audio an option's audio details
  * @property {boolean} is_correct true/false if the option is correct
*/

/**
 * Forms the payload needed to update a Question in the database
 * @param {Question} question
 * @return {FormData} a question represented as form Data
 */
function updateQuestionRequestObject(question) {
  let originalFilename = "";
  if (question.instructions.audio !== undefined)
    originalFilename = question.instructions.audio.file_name;

  const data = new FormData();
  data.append("audio", question.audioFile);
  data.append("originalFilename", originalFilename);
  data.append("pg_table_name", question.pg_table_name);
  data.append("instructions", JSON.stringify(question.instructions));
  data.append("options", JSON.stringify(question.options));
  data.append("morphemes", JSON.stringify(question.morphemes));

  return data;
}

/**
 * Takes in a question to update, creates it's [payload]{@link module:Question-API-Interface~updateQuestionRequestObject},
 * and then calls for it's update in the database
 * @param props
 * @param {Question} props.question
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function updateQuestion(props) {
  let updateQuestionURL =
    process.env.REACT_APP_BACKEND_MC_QUESTION_URI +
    "update/" +
    props.question.question_id;
  let requestBody = updateQuestionRequestObject(props.question);

  try {
    return await axios.post(
        updateQuestionURL,
        requestBody,
        getAuthorizationHeadersObject()
    );
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, try again.",
    };
  }
}

/**
 * Takes in a question and adds it to the database
 * @param {Question} props
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function addQuestion(props) {
  let addQuestionUrl = process.env.REACT_APP_BACKEND_MC_QUESTION_URI + "add"
  let questionBody = {
    pg_table_name:props.pg_table_name,
    instructions:props.instructions,
    options:props.options,
    morphemes:props.morphemes,
    __t:props.__t,
    lesson_id: props.lesson_id,
  }

  try{
    return (await axios.post(addQuestionUrl, questionBody, getAuthorizationHeadersObject())).data
  } catch(error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, try again.",
    };  }

}

/**
 * Retrieves a question from the database
 * @param props
 * @param {string} props.question_id the ID of the question to retrieve
 * @return {Promise<any>} the [Question]{@link module:Question-API-Interface~Question}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getQuestion(props) {
  let getQuestionURL =
    process.env.REACT_APP_BACKEND_MC_QUESTION_URI + props.question_id;

  let questionResponse;
  try {
    questionResponse = await axios.request({
      method: "GET",
      url: getQuestionURL,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, refresh your page.",
    };
  }

  let question = questionResponse.data;
  if (question.instructions === undefined) {
    question.instructions = {
      text_cree: "",
      text_eng: "",
    };
  }

  return question;
}

/**
 * Gets the [audio URL]{@link module:Question-API-Interface~getAudioFileURL} then calls on the download of audio configured for a quiz option
 * to the calling machine
 * @param props
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function downloadAudio(props) {
  let downloadAudioURL = getAudioFileURL(props);

  try {
    return await axios.request({
      method: "GET",
      url: downloadAudioURL,
      headers: getAuthorizationHeadersObject().headers,
      responseType: "blob",
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
    };
  }
}

/**
 * Gets the [audio URL]{@link module:Question-API-Interface~getAudioFileURL} then calls for it's deletion from the passed option
 * @param  props
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function deleteAudio(props) {
  let deleteAudioURL = getAudioFileURL(props);

  try {
    return await axios.request({
      method: "DELETE",
      url: deleteAudioURL,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
    };
  }
}

/**
 * Takes in a quiz option
 * @summary takes in a payload of {questionID,optionId} then gets the link to one of their audio.
 * @param {string} props.question_id the ID of the question to get the audio URL for or parent of quiz option
 * @param {string|undefined} props.option_id the ID of the quiz opiton to get the audio URL for
 * @return {string} the URL for the audio of a [QuizOption]{@link module:Question-API-Interface~QuizOption} if optionId provided
 * @return {string} the URL for the audio of a [Question]{@link module:Question-API-Interface~Question} if no option Id provided
 */
function getAudioFileURL(props) {
  const baseURL =
    process.env.REACT_APP_BACKEND_MC_QUESTION_URI +
    "media/audio/" +
    props.question_id;
  if (props.option_id === undefined) {
    return baseURL;
  } else {
    return baseURL + "/" + props.option_id;
  }
}

/**
 * Builds the payload needed to update an option for a quiz in the database.
 * @param props
 * @param {QuizOption} props.option the updated option definition
 * @param  props.audioFile the audio definition for the passed option
 * @return {FormData} the payload needed to update a quiz option as Form Data
 */
function updateOptionRequestObject(props) {

  let originalFilename = "";
  if (props.option.audio !== undefined)
    originalFilename = props.option.audio.file_name;

  const data = new FormData();
  data.append("audio", props.audioFile);
  data.append("originalFilename", originalFilename);
  data.append("option", JSON.stringify(props.option));

  return data;
}

/**
 * Builds the [update option payload]{@link module:Question-API-Interface~updateOptionRequestObject} then calls on the API to update the option with
 * the passed spec.
 * @param props
 * @param {string} props.question_id the ID of the question to which the option being updated belongs
 * @param {QuizOption} props.option the option being updated
 * @param  props.audioFile the audio definition for the passed option
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function updateAnswerOption(props) {
  let updateQuestionURL =
    process.env.REACT_APP_BACKEND_MC_QUESTION_URI +
    "update/" +
    props.question_id +
    "/answerOption/";

  //Note - this appears to be an empty body... Same for props. I'm unsure of why - this is just how it is...
  let requestBody = updateOptionRequestObject(props);


  try {
    return await axios.post(
        updateQuestionURL,
        requestBody,
        getAuthorizationHeadersObject()
    );
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, try again.",
    };
  }
}

const questionAPIInterface = {
  getQuestion,
  addQuestion,
  updateQuestion,
  downloadAudio,
  deleteAudio,
  updateAnswerOption,
};

export default questionAPIInterface;
