import React, {Component} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import Instructions from "../instructions.component";
import urlParser from "../../Utils/parseIdsFromURL";
import APIInterface from "../APIInterface";
import {unauthorizedErrorMessage} from "../../Utils/authorization";
import LoadingSpinner from "../../Utils/LoadingSpinner";
import MorphemesTable from "../../ContentPage/Phrase/morphemesTable.component";
import {TransparentButton} from "../../Utils/Buttons";
import changeTableName from "../../Utils/changeElementName";

/**
 * @class
 * The Multiple-Choice Question Component
 * @see Instructions
 * @see QuestionAnswers
 */
class WSQuestion extends Component {

    /**
     * @constructor
     * @param props the payload needed to construct a WSQuestion component
     */
    constructor(props) {
        super(props);

        this.updateInstructions = this.updateInstructions.bind(this);
        this.updateMorphemes = this.updateMorphemes.bind(this);
        this.updateTableName = this.updateTableName.bind(this);
        this.onClickSave = this.onClickSave.bind(this);

        let elementsId = urlParser.MCQuestionParser(window.location.href);
        this.state = {
            pg_table_name: "",
            instructions: {
                text_cree: "",
                text_eng: "",
            },
            morphemes: [],
            __t:"WS",
            question_id: elementsId.question_id,
            lesson_id: elementsId.lesson_id,
            saveButtonText: "Save Question",
            errorMessage: "",
            audioFile: "",
            showLoading:true,
        };
    }

    /**
     * Builds the WSQuestion once mounted
     * @summary When the component mounts, it is determined whether or not this is a new question. If so, a blank
     * Question creation page is rendered, otherwise the details are retrieved through the
     * [getQuestion]{@link module:Question-API-Interface~getQuestion} call and allows for editing
     * @return {Promise<void>}
     */
    async componentDidMount() {

        if(this.state.question_id !== "newQuestion") {
            let question = await APIInterface.getQuestion({
                question_id: this.state.question_id,
            });

            if (question.errorStatus === undefined) {
                this.setState({
                    pg_table_name: question.pg_table_name,
                    instructions: question.instructions,
                    morphemes: question.morphemes,
                    showLoading: false,
                });
            } else if (question.errorStatus === 401) {
                window.location = "/login";
            } else {
                this.setState({
                    errorMessage: question.errorMessage,
                });
            }
        } else {
            this.setState({showLoading:false});
        }
    }

    /**
     * Updates the instructions component of the WSQuestion in the database
     * @summary This method updates the entirety of the question's Instruction component, though maybe not both at once.
     * When used to update audio, it may not have instructions, when used to update instructions, it may not have audio.
     * @param {string} newInstructions the updated instructions component of a Question
     * @param newAudio the updated audio definition of the Questions Audio
     */
    updateInstructions = (newInstructions,newAudio) => {
        this.setState({
            instructions: newInstructions,
        });

        if(newAudio){
            this.setState({
                audio: newAudio.media,
                audioFile: newAudio.mediaFile,
            })
        }
    };


    /**
     * Handles the on-click for adding a morpheme
     * @see #blankMorpheme
     */
    onClickAddMorpheme = () => {
        let updatedMorphemesList = this.state.morphemes;
        updatedMorphemesList.push({
            position:this.state.morphemes.length,
            morph_cree:"",
            morph_english: ""
        });
        this.updateMorphemes(updatedMorphemesList);
    };

    /**
     * Updates the quiz morphemes list
     * @param {Array<module:Question-API-Interface~Morpheme>} newMorphemes updated definition of the WSQ morphemes
     */
    updateMorphemes= (newMorphemes) => {
        this.setState({
            morphemes: newMorphemes,
        });
    };

    /**
     * Updates the name of the question in the database
     * @param e
     * @param {string} e.target.value the new name for the question in the database
     */
    updateTableName = (e) => {
        this.setState({
            pg_table_name: e.target.value,
        });
    };

    /**
     * Checks that the answer list has a correct answer within it.
     * @summary Validates the answer list by checking that there is at least one answer, and that there is one answer
     * that has been identified as the correct answer
     * @returns true if there is more than 1 morpheme
     * @returns false if there is are not at least 2 morphemes
     */
    validateAnswers= () => {
        return this.state.morphemes.length > 1;
    }

    /**
     * Saves the current Question to the Database
     * @summary If the question is a new question, we call [addQuestion]{@link module:Question-API-Interface~addQuestion}.
     * If the question exists, then it we call [updateQuestion]{@link module:Question-API-Interface~updateQuestion} to
     * update it. Both scenarios result in reloading the page to its existing or new question_id
     * @return {Promise<void>}
     */
    async onClickSave() {
        this.setState({
            saveButtonText: "Saving... Please wait.",
            errorMessage: "",
        });

        let saveResult;
        if(this.validateAnswers() === false){
            saveResult = {errorMessage: "Error: Must have at least 2 morphemes in answer", errorStatus:"Internal"};
        } else if(this.state.question_id === "newQuestion"){
            saveResult = await APIInterface.addQuestion(this.state)
            this.setState({question_id:saveResult})
        } else {
            saveResult = await APIInterface.updateQuestion({
                question: this.state,
            });
        }

        if (saveResult.errorStatus === undefined) {
            window.location = "/lesson/" + this.state.lesson_id + "/wsQuestion/" + this.state.question_id;
        } else {
            if (saveResult.errorStatus === 401) {
                this.setState({
                    errorMessage: unauthorizedErrorMessage,
                    saveButtonText: "Save Question (Login first)",
                });
                window.open("/login", "_blank");
            } else {
                this.setState({
                    errorMessage: saveResult.errorMessage,
                    saveButtonText: "Save Question",
                });
            }
        }
    }

    /**
     * Renders an Multiple Choice Question Page
     * @return {JSX.Element} the WSQuestion Page
     * @see LoadingSpinner
     * @see Instructions
     * @see QuestionAnswers
     */
    render() {
        return (
            <div className="ml-3 mr-4">

                {this.state.showLoading && <LoadingSpinner top={'50%'} left={'50%'}/>}

                {!this.state.showLoading &&
                    <div>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href={`/lesson/${this.state.lesson_id}`}>
                                Lesson
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Word Shuffle Question</Breadcrumb.Item>
                        </Breadcrumb>
                        <h3 className="mb-4">{this.state.pg_table_name || "New Word Shuffle Question"}</h3>
                        <Instructions
                            updateStateFunction={this.updateInstructions}
                            elementId={this.state.question_id}
                            saveFunction={this.onClickSave}
                        >
                            {this.state.instructions}
                        </Instructions>
                        <br/>
                        {this.state.morphemes.length > 0 &&
                            <div>
                            <MorphemesTable
                                updateStateFunction={this.updateMorphemes}>
                                {this.state.morphemes}
                            </MorphemesTable>
                            <p>
                                <b>Correct Ordering:</b><br/>
                                <i>{this.state.morphemes.map(m => m.morph_cree).join(' ')}</i><br/>
                                {this.state.morphemes.map(m => m.morph_english).join(' ')}
                            </p>
                            </div>
                        }

                        <div className="form-row mb-3">
                            <TransparentButton
                                disabled={this.state.morphemes.length >= 30}
                                onClick={this.onClickAddMorpheme}>
                                Add Morpheme
                            </TransparentButton>
                        </div>

                        {changeTableName(
                            "Question",
                            this.state.pg_table_name,
                            this.updateTableName
                        )}
                        <div className="form-group">
                            <button className="btn btn-dark" onClick={this.onClickSave}>
                                {this.state.saveButtonText}
                            </button>
                        </div>
                        <label style={{ color: "red" }}>{this.state.errorMessage}</label>
                    </div>
                }
            </div>
        );
    }
}
export default WSQuestion;
