import axios from "axios";
import {getAuthorizationHeadersObject} from "../../Utils/authorization";

/**
 * @module ManageUsers-API-Interface
 */

/**
 * Gets a list of all users that are approved
 * @summary again... what a poorly named method. Uses the Access Control service to retrieve a list of all
 * users that are currently approved in the system
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getElement() {
  let getRequestsURL =
    process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "users/active";

  let usersAPIResponse;
  try {
    usersAPIResponse = await axios.request({
      method: "GET",
      url: getRequestsURL,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, refresh your page.",
    };
  }

  return usersAPIResponse.data;
}

/**
 * Updates the Role of a user in the database
 * @param {string} email the email of the user to update the role of
 * @param {"admin"|"editor"|"author"|"ReadOnly"} role the role to assign to the user with the passed email
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function updateRole(email, role) {
  let postRequestURL =
    process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "user/updateRole";

  try {
    return await axios.post(
        postRequestURL,
        {email: email, role: role},
        getAuthorizationHeadersObject()
    );
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, try again.",
    };
  }
}

/**
 * Deletes a user from the database
 * @param {string} email the email of the user to delete from the database
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function deleteUser(email) {
  let deleteURL = process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "user";

  try {
    return await axios.request({
      method: "DELETE",
      data: {email: email},
      url: deleteURL,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, try again.",
    };
  }
}

/**
 * Checks the validity of the callers token
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function checkAuthorization(){
  let verifyUrl = process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "/verify-jwt-integration";

  try{
    return await axios.get(verifyUrl,getAuthorizationHeadersObject());
  } catch (error){
    return {
      errorStatus: error.response.status,
      errorMessage: `ERROR: ${error.response.data}`
    }
  }
}


const userManAPIInterface = {
  getElement,
  updateRole,
  deleteUser,
  checkAuthorization,
};

export default userManAPIInterface;
