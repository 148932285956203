import axios from "axios";
import {getAuthorizationHeadersObject} from "../../Utils/authorization";
import {init, send} from "@emailjs/browser";

/**
 * @module ManageRequests-API-Interface
 */

/**
 * Gets a list of all inactive (unapproved) users
 * @summary somebody very lazy created this portion of the app.  This method name is so terribly vague that
 * It needs to be fixed.
 * @todo find all of the "getElement" API calls and name them something more helpful
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getElement() {
  let getRequestsURL =
    process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "users/inactive";

  let requestsAPIResponse;
  try {
    requestsAPIResponse = await axios.request({
      method: "GET",
      url: getRequestsURL,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, refresh your page.",
    };
  }

  return requestsAPIResponse.data;
}

/**
 * Updates the role of a user in the database
 * @param user the user to update the role of
 * @param {"admin"|"editor"|"author"|"ReadOnly"} role the role to assign the user with the given email
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function updateRole(user,role) {
  let postRequestURL =
    process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "user/updateRole";
  try {
    return await axios.post(
        postRequestURL,
        {email: user.email, role: role},
        getAuthorizationHeadersObject()
    )
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, try again.",
    };
  }
}

/**
 * Calls for the Denial of a users access to the API
 * @param {string} email the email of the requesting user to deny access of.
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function denyRequest(email){
  let denyRequestURL =
      process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "user/denyRequest";

  try {
    return await axios.post(
        denyRequestURL,
        {email: email},
        getAuthorizationHeadersObject()
    );
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, try again.",
    };
  }
}

/**
 * Sends an email to notify of role change
 * @param user the user to mail notification to
 * @param {string} role - the previous role of the user.
 * @param {string} user.role - role the user has been granted
 * @param {string} user.username - username of user that was granted access
 * @param {string} user.email - the email of the user to mail the notification to.
 */
async function sendEmail(user,role) {

  console.log(`sending to: ${JSON.stringify(user)}`);
  init(process.env.EMAIL_USER_ID);
  let params = {
    send_to:user.email,
    prevRole: user.role ,
    role: role,
    username: user.username,
    from_name: "nisotak project"
  };
  return send(process.env.REACT_APP_EMAIL_SERVICE_ID,process.env.REACT_APP_EMAIL_TEMPLATE_ID,params,process.env.REACT_APP_EMAIL_USER_ID)
      .catch(err => console.error(`failed to send email: ${err}`))
}

const requestManAPIInterface = {
  getElement,
  updateRole,
  denyRequest,
  sendEmail
};

export default requestManAPIInterface;