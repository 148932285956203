import React, {Component} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ConfirmDelete from "../Utils/confirmDelete.component";
import urlParser from "../Utils/parseIdsFromURL";
import APIInterface from "./APIInterface";
import {unauthorizedErrorMessage} from "../Utils/authorization";
import MediaSection from "../Utils/Media/media.component";
import LoadingSpinner from "../Utils/LoadingSpinner";
import {Button, ButtonGroup} from "react-bootstrap";
import {ArrowDown, ArrowUp} from "react-bootstrap-icons";

/**
 * @const
 * A simple function that counts the occurrences of an item in an array. Uses reduce because
 * reduce is boss
 * @param arr the array to search through/reduce
 * @param val the value to search for/count
 * @returns {number} - the number of occurrences of val in arr
 */
const countOccurences = (arr,val) => arr.reduce((a,v) => (v === val ? a+1:a),0)

/**
 * @class
 * The Lesson Component Page
 */
class Lesson extends Component {


  /**
   * @constructor
   * @param props the payload used to create a Lesson Component
   * @see module:urlParser~LessonPageParser
   */
  constructor(props) {
    super(props);

    // Lesson fields
    this.onChangeTitleCree = this.onChangeTitleCree.bind(this);
    this.onChangeTitleEng = this.onChangeTitleEng.bind(this);
    this.onChangeIntroCree = this.onChangeIntroCree.bind(this);
    this.onChangeIntroEng = this.onChangeIntroEng.bind(this);
    this.onChangeTopicCree = this.onChangeTopicCree.bind(this);
    this.onChangeTopicEng = this.onChangeTopicEng.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeDialect = this.onChangeDialect.bind(this);
    this.onChangeKnowledgeSource = this.onChangeKnowledgeSource.bind(this);
    this.onChangeLevel = this.onChangeLevel.bind(this);
    this.onClickNewContent = this.onClickNewContent.bind(this);
    this.onChangePageOrder = this.onChangePageOrder.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.closeDeleteConfirmationModal = this.closeDeleteConfirmationModal.bind(
        this
    );
    this.saveLesson = this.saveLesson.bind(this);
    // Media
    this.onChangeAudio = this.onChangeAudio.bind(this);
    this.downloadAudio = this.downloadAudio.bind(this);
    this.deleteAudio = this.deleteAudio.bind(this);

    let lesson_id = urlParser.LessonPageParser(window.location.href).lesson_id;
    this.state = {
      title_cree: "",
      title_eng: "",
      intro_cree: "",
      intro_eng: "",
      topic_cree: "",
      topic_eng: "",
      location: "",
      dialect: "",
      knowledge_source: "",
      author: "",
      level: "",
      difficulty: "",
      audioFile: "",
      audio: undefined,
      levelOpt: ["-", "Beginner", "Intermediate", "Advanced"],
      difficultyOpt: ["-", "Easy", "Normal", "Hard"],
      pages: [],
      errorMessage: "",
      saveButtonText: "Save Lesson",
      showDeleteConfirmationModal: false,
      deleteItemIndex: undefined,
      lesson_id: lesson_id,
      published: false,
      showLoading: true,
      showQuizTypes: false,
    };
  }

  /**
   * Builds a Lesson once mounted
   * @summary Using the ID set in state, it is determined whether this is a new or existing lesson we are representing.
   * If it is a new one, we can forgo filling the page with details; otherwise we must [Get the Lesson]{@link module:Lesson-API-Interface~getElement}
   * and get it's existing [pages]{@link module:Lesson-API-Interface~getContentTableNames} and update our state with the
   * retrieved info
   * @return {Promise<void>}
   */
  async componentDidMount() {

    if (this.state.lesson_id !== "newLesson") {
      let lesson = await APIInterface.getElement(this.state.lesson_id);
      if (lesson.errorStatus === undefined) {
        lesson.pages.sort(function (a, b) {
          return a.pg_display_order - b.pg_display_order;
        });
        let lessonPages = await APIInterface.getContentTableNames(lesson.pages);
        if (lessonPages.errorStatus === undefined) {
          this.setState({
            title_cree: lesson.title_cree,
            title_eng: lesson.title_eng,
            intro_cree: lesson.intro_cree,
            intro_eng: lesson.intro_eng,
            topic_cree: lesson.topic_cree,
            topic_eng: lesson.topic_eng,
            location: lesson.location,
            dialect: lesson.dialect,
            knowledge_source: lesson.knowledge_source,
            author: lesson.author,
            level: lesson.level,
            difficulty: lesson.difficulty,
            audio: lesson.audio,
            pages: lessonPages,
            published: lesson.published,
            showLoading: false,
          });
        } else if (lessonPages.errorStatus === 401) {
          window.location = "/login";
        } else {
          this.setState({
            errorMessage: lessonPages.errorMessage,
          });
        }
      } else if (lesson.errorStatus === 401) {
        window.location = "/login";
      } else {
        this.setState({
          errorMessage: lesson.errorMessage,
        });
      }
    } else {
      this.setState({
        showLoading: false
      })
    }
  }

  /**
   * Changes state to reflect a new value for taught language title
   * @param e
   * @param {string} e.target.value the new value for taught language title
   */
  onChangeTitleCree = (e) => {
    this.setState({
      title_cree: e.target.value,
    });
  };

  /**
   * Changes state to reflect a new value for english title
   * @param e
   * @param {string} e.target.value the new value for english title
   */
  onChangeTitleEng = (e) => {
    this.setState({
      title_eng: e.target.value,
    });
  };

  /**
   * Changes state to reflect a new value for taught language intro
   * @param e
   * @param {string} e.target.value the new value for taught language intro
   */
  onChangeIntroCree = (e) => {
    this.setState({
      intro_cree: e.target.value,
    });
  };

  /**
   * Changes state to reflect a new value for english intro
   * @param e
   * @param {string} e.target.value the new value for english intro
   */
  onChangeIntroEng = (e) => {
    this.setState({
      intro_eng: e.target.value,
    });
  };

  /**
   * Changes state to reflect a new value for taught language topic
   * @param e
   * @param {string} e.target.value the new value for taught language topic
   */
  onChangeTopicCree = (e) => {
    this.setState({
      topic_cree: e.target.value,
    });
  };

  /**
   * Changes state to reflect a new value for english topic
   * @param e
   * @param {string} e.target.value the new value for english topic
   */
  onChangeTopicEng = (e) => {
    this.setState({
      topic_eng: e.target.value,
    });
  };

  /**
   * Changes state to reflect a new value for location
   * @param e
   * @param {string} e.target.value the new value for location
   */
  onChangeLocation = (e) => {
    this.setState({
      location: e.target.value,
    });
  };

  /**
   * Changes state to reflect a new value for dialect
   * @param e
   * @param {string} e.target.value the new value for dialect
   */
  onChangeDialect = (e) => {
    this.setState({
      dialect: e.target.value,
    });
  };

  /**
   * Changes state to reflect a new value for knowledge source
   * @param e
   * @param {string} e.target.value the new value for knowledge source
   */
  onChangeKnowledgeSource = (e) => {
    this.setState({
      knowledge_source: e.target.value,
    });
  };

  /**
   * Changes state to reflect a new value for level
   * @param e
   * @param {"Beginner"|"Intermediate"|"Advanced"} e.target.value the new value for level
   */
  onChangeLevel = (e) => {
    this.setState({
      level: e.target.value,
    });
  };

  /**
   * Changes state to reflect a new value for difficulty
   * @param e
   * @param {"Easy"|"Normal"|"Hard"} e.target.value the new value for difficulty
   */
  onChangeDifficulty = (e) => {
    this.setState({
      difficulty: e.target.value,
    });
  };

  /**
   * Toggles the published state of this lesson
   * @summary Calls on the API to [Set Published]{@link module:Lesson-API-Interface~changePublished} to the passed value
   * for the current lesson
   * @param {true|false} doPublish true/false do we want to publish this lesson?
   * @return {Promise<void>}
   */
  changePublish = async (doPublish) => {
    let {lesson_id} = this.state;
    let result = await APIInterface.changePublished({doPublish: doPublish, lesson_id: lesson_id})
    if (result.errorStatus === undefined) {
      this.setState({published: result})
    } else if (result.errorStatus === 403) {
      window.location = '/login'
    } else {
      console.error(`Failed to publish lesson: ${JSON.stringify(result)}`);
    }
  }

  /**
   * Saves the Lesson to the database
   * @summary if this is a new Lesson, the lesson is [added]{@link module:Lesson-API-Interface~addLesson}
   * and we are redirected to the page representing the Lesson in the DB (as it now has an ID in the DB), otherwise
   * the lesson is [updated]{@link module:Lesson-API-Interface~updateElement} and we simply refresh the page.
   * @param {string} window_address the address to redirect to after save
   * @return {Promise<boolean>}
   */
  saveLesson = async (window_address) => {
    this.setState({
      saveButtonText: "Saving... Please wait.",
      errorMessage: "",
      showLoading: true,
    });

    let lesson = this.state;
    let saveResult;
    if (this.state.lesson_id === "newLesson") {
      saveResult = await APIInterface.addLesson(lesson)
      this.setState({lesson_id: saveResult})
    } else {
      saveResult = await APIInterface.updateElement({
        fullElement: this.state,
      });
      this.setState({audio: saveResult.data})
    }
    if (saveResult.errorStatus === undefined) {
      if (window_address !== "stay") {
        //If we are creating a new page, we don't want to navigate
        if (window_address !== "newPage") {
          window.location = window_address;
        }
      } else {
        window.location = '/lesson/' + this.state.lesson_id;

        this.setState({
          saveButtonText: "Save Lesson",
          errorMessage: "",
          showLoading: false,
        });
        return true;
      }
    } else {
      if (saveResult.errorStatus === 401) {
        this.setState({
          errorMessage: unauthorizedErrorMessage,
          saveButtonText: "Save Lesson (Login first)",
          showLoading: false,
        });
        window.open("/login", "_blank");
        return false;
      } else {
        this.setState({
          errorMessage: saveResult.errorMessage,
          saveButtonText: "Save Lesson",
          showLoading: false,
        });
        return false;
      }
    }
  };

  /**
   * Adds new Content To the Lesson
   * @summary first [Saves]{@link #saveLesson} the lesson, then redirects us to a page to create either a new
   * Multiple choice Question, or a new Content Page.
   * @param e
   * @param {"Content Page"|"Multiple Choice Question"} e.target.name the type of content to create
   * @return {Promise<void>}
   */
  onClickNewContent = async (e) => {

    let contentType = e.target.name;
    await this.saveLesson("newPage")
        .then(() => {
          let redirect = window.location.href;

          if (contentType === "Content Page") {
            redirect =
                "/lesson/" +
                this.state.lesson_id +
                "/contentPage/" +
                "newContent"
          } else if (contentType === "Multiple Choice Question") {
            redirect =
                "/lesson/" +
                this.state.lesson_id +
                "/mcQuestion/" +
                "newQuestion"
          } else if (contentType === "Word Shuffle Question") {
            redirect =
                "/lesson/" +
                this.state.lesson_id +
                "/wsQuestion/" +
                "newQuestion"
          } else {
            console.error(`Unknown content type requested: ${contentType}`);
          }
          window.location = redirect
        });
  };

  /**
   * Changes the order in which the pages are displayed
   * @summary I actually don't know the specifics of this
   * @param e
   * @param {string} e.dir - the direction to move the item
   * @param {string} e.idx - the index of the item that the button was clicked on
   */
  onChangePageOrder = (e) => {

    let updatedPageList = this.state.pages; //the new list
    let swpIdx = parseInt(e.idx); //the idx of the one we clicked the button on
    let dir = e.dir; //The direction we need to move it
    let targetIdx = -1; //The index of who we are swapping with. SHOULD NOT STAY -1, THIS IS FOR CATCHING ERRORS

    //Setup our Swap
    if (swpIdx === 0 && dir === 'up')
      targetIdx = updatedPageList.length - 1;
    else if (swpIdx === updatedPageList.length - 1 && dir === 'down')
      targetIdx = 0;
    else if (dir === 'up')
      targetIdx = swpIdx - 1;
    else if (dir === 'down')
      targetIdx = swpIdx + 1;
    else
      console.error(`SWPi:${swpIdx} TARi: ${targetIdx} wtf`);

    //Grab the elements from the original list
    let swp = this.state.pages[swpIdx];
    let target = this.state.pages[targetIdx];

    //Switch the position _fields_ on the items for edge cases
    if (targetIdx === 0 && swpIdx === this.state.pages.length) {
      swpIdx = 1;
      targetIdx = this.state.pages.length + 1
    }


    target.pg_display_order = swpIdx + 1;
    swp.pg_display_order = targetIdx + 1;

    //swap the positions of the items themselves in the array
    updatedPageList[swpIdx] = target;
    updatedPageList[targetIdx] = swp;

    //Update the parent with the new list.
    this.setState({pages: updatedPageList});


  }

  /**
   * Navigates to a Sub-page for editing
   * @param e
   * @param {"Content Page"|"Multiple Choice Question"|"Word Shuffle Question"} e.target.value the type of page that was requested for edit
   */
  onClickEdit = (e) => {
    let nextPageRedirect = "/lesson/" + this.state.lesson_id;

    if (this.state.pages[e.target.id].pg_type === "Content Page") {
      nextPageRedirect = nextPageRedirect + "/contentPage/" + e.target.value;
    } else if (
        this.state.pages[e.target.id].pg_type === "Multiple Choice Question"
    ) {
      nextPageRedirect = nextPageRedirect + "/mcQuestion/" + e.target.value;
    } else if (
        this.state.pages[e.target.id].pg_type === "Word Shuffle Question"
    ) {
      nextPageRedirect = nextPageRedirect + "/wsQuestion/" + e.target.value; //Same endpoint. no need to change
    }

    this.saveLesson(nextPageRedirect).catch(err => console.error(`Error saving lesson: ${err}`));
  };

  /**
   * Changes state to show the [delete confirmation modal]{@link ConfirmDelete}
   * @param e
   * @param {string} e.target.id the ID of the item we are going to delete
   */
  onClickDelete = (e) => {
    this.setState({
      showDeleteConfirmationModal: true,
      deleteItemIndex: e.target.id,
    });
  };

  /**
   * Dismisses the the [delete confirmation modal]{@link ConfirmDelete}
   * @callback ConfirmDelete~closeModalCallback
   */
  closeDeleteConfirmationModal = () => {
    this.setState({
      showDeleteConfirmationModal: false,
      deleteItemIndex: undefined,
    });
  };

  /**
   * Handles the delete [delete confirmation modal]{@link ConfirmDelete~closeModalCallback}
   * @summary first [Saves]{@link #saveLesson} the lesson then  calls on the API to
   * [Delete Content]{@link module:Lesson-API-Interface~deleteContent}
   * @callback ConfirmDelete~deleteFunction
   * @param {string} elemIdx the Index of the element to delete in the page list
   * @return {Promise<void>}
   */
  onConfirmDelete = async (elemIdx) => {
    this.setState({
      showDeleteConfirmationModal: false,
    });

    if (this.state.pages[elemIdx]._id === undefined) {
      //Really, this statement is a saftey measure, when we pull the list of pages in, all pages that have cause
      // an issue being fetched are removed.
      let {newPagesList} = this.state.pages;
      newPagesList.splice(elemIdx, 1);
      this.setState({pages:newPagesList});
    } else {
      let deleteResult = await APIInterface.deleteContent(
          this.state.pages[elemIdx].pg_id,
          this.state.pages[elemIdx].pg_type,
          this.state.pages[elemIdx]._id
      );
      if (deleteResult.errorStatus === undefined) {
        window.location = "/lesson/" + this.state.lesson_id;
      } else {
        this.setState({errorMessage: deleteResult.errorMessage})
        if (deleteResult.errorStatus === 401) {
          this.setState({
            errorMessage: unauthorizedErrorMessage,
            saveButtonText: "Save Lesson (Login first)",
          });
          window.open("/login", "_blank");
        }
      }
    }
    this.setState({
      saveButtonText: "Save Lesson",
    });
};

/**
 * Changes the audio configured for this lesson
   * @param audioObject
   * @param audioObject.mediaFile the name/details of the file
   * @param audioObject.media the object containing the audio definition
   */
  onChangeAudio = (audioObject) => {
    this.setState({
      audio: audioObject.media,
      audioFile: audioObject.mediaFile,
    });
  };

  /**
   * Downloads lesson audio to the host machine
   * @see [Download Audio]{@link module:Lesson-API-Interface~downloadAudio}
   * @return {Promise<*>}
   */
  async downloadAudio() {
    return await APIInterface.downloadAudio({
      element_id: this.state.lesson_id,
    });
  }

  /**
   * Deletes audio from the lesson.
   * @see [Delete Audio]{@link module:Lesson-API-Interface~deleteAudio}
   * @return {Promise<*>}
   */
  async deleteAudio() {
    return APIInterface.deleteAudio({
      element_id: this.state.lesson_id,
    });
  }

  /**
   * Renders a Lesson Page
   * @return {JSX.Element} the Lessson Page
   * @see LoadingSpinner
   * @see [MediaSection]{@link module:MediaSectionComponent~MediaSection}
   * @see ConfirmDelete
   */
  render() {
    return (
        <div className="ml-3 mr-4">

          {this.state.showLoading && <LoadingSpinner top={'50%'} left={'50%'}/>}

          {!this.state.showLoading &&
          <div>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Lesson</Breadcrumb.Item>
            </Breadcrumb>

            <h3 className="mb-4">{this.state.title_cree || "New Lesson"}</h3>
            <h5 className="mb-4"><i>{this.state.title_eng || "New Lesson"}</i></h5>
            {this.state.lesson_id !== "newLesson" &&
            <div>
              <label>Created by: {this.state.author}</label>
            </div>
            }
            {this.state.lesson_id !== "newLesson" &&
            <div align="right">
              {this.state.published &&
              <button
                  onClick={() => this.changePublish(false)}
                  className="btn btn-light">Un-publish
              </button>
              }
              {!this.state.published &&
              <button
                  onClick={() => this.changePublish(true)}
                  className="btn btn-dark">Publish</button>
              }
            </div>
            }

            <h5 className="mt-3 mb-3">About the Language</h5>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Location: </label>
                <input
                    type="text"
                    required
                    className="form-control"
                    placeholder={this.state.location}
                    value={this.state.location}
                    onChange={this.onChangeLocation}
                />
              </div>

              <div className="form-group col-md-6">
                <label className="font-weight-bold">Dialect: </label>
                <input
                    type="text"
                    required
                    className="form-control"
                    placeholder={this.state.dialect}
                    value={this.state.dialect}
                    onChange={this.onChangeDialect}
                />
              </div>

              <div className="form-group col-md-6">
                <label className="font-weight-bold">Knowledge source: </label>
                <input
                    type="text"
                    required
                    className="form-control"
                    placeholder={this.state.knowledge_source}
                    value={this.state.knowledge_source}
                    onChange={this.onChangeKnowledgeSource}
                />
              </div>
            </div>

            <h5 className="mt-3 mb-3">Lesson Details</h5>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Level: </label>
                <select
                    ref="userInput"
                    required
                    className="form-control"
                    value={this.state.level}
                    onChange={this.onChangeLevel}
                >
                  {this.state.levelOpt.map(function (level) {
                    return (
                        <option key={level} value={level}>
                          {level}
                        </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Difficulty: </label>
                <select
                    ref="userInput"
                    required
                    className="form-control"
                    value={this.state.difficulty}
                    onChange={this.onChangeDifficulty}
                >
                  {this.state.difficultyOpt.map(function (difficulty) {
                    return (
                        <option key={difficulty} value={difficulty}>
                          {difficulty}
                        </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="font-weight-bold">
                  Title (Indigenous Language):{" "}
                </label>
                <input
                    type="text"
                    required
                    className="form-control"
                    placeholder={this.state.title_cree}
                    value={this.state.title_cree}
                    onChange={this.onChangeTitleCree}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Title (English): </label>
                <input
                    type="text"
                    required
                    className="form-control"
                    placeholder={this.state.title_eng}
                    value={this.state.title_eng}
                    onChange={this.onChangeTitleEng}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="font-weight-bold">
                  Topic (Indigenous Language):{" "}
                </label>
                <input
                    type="text"
                    required
                    className="form-control"
                    placeholder={this.state.topic_cree}
                    value={this.state.topic_cree}
                    onChange={this.onChangeTopicCree}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Topic (English): </label>
                <input
                    type="text"
                    required
                    className="form-control"
                    placeholder={this.state.topic_eng}
                    value={this.state.topic_eng}
                    onChange={this.onChangeTopicEng}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="font-weight-bold">
                  Description (Indigenous Language)
                </label>
                <textarea
                    rows="6"
                    required
                    className="form-control"
                    placeholder={this.state.intro_cree}
                    value={this.state.intro_cree}
                    onChange={this.onChangeIntroCree}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Description (English)</label>
                <textarea
                    rows="6"
                    required
                    className="form-control"
                    placeholder={this.state.intro_eng}
                    value={this.state.intro_eng}
                    onChange={this.onChangeIntroEng}
                />
              </div>
            </div>

            {this.state.lesson_id === "newLesson" &&
            <button
                className="btn btn-dark"
                onClick={this.saveLesson.bind(this, "stay")}
            > Add Audio
            </button>}

            {this.state.lesson_id !== "newLesson" &&
            <MediaSection
                onChangeFile={this.onChangeAudio}
                downloadMediaAPICall={this.downloadAudio}
                deleteMediaAPICall={this.deleteAudio}
                reloadPageAfterDelete={true}
                title="Audio"
                mediaType="audio"
            >
              {this.state.audio}
            </MediaSection>
            }

            <h5 className="mt-3 mb-3">Lesson Flow</h5>

            <p>
              The lesson flow is composed by content pages and questions, which can
              be combined and ordered freely.
            </p>

            <div className="form-row ">
              <div className="form-group col-sl-2">
                <button
                    name={"Content Page"}
                    className="btn btn-outline-dark"
                    onClick={this.onClickNewContent}
                >
                  New Content Page
                </button>
              </div>
              <div className="form-group ml-2">
                <button
                    name={"Question"}
                    className="btn btn-outline-dark"
                    onClick={() => {
                      let showTypes = this.state.showQuizTypes;
                      this.setState({showQuizTypes:!showTypes});
                    }}
                >
                  New Question
                </button>
              </div>
            </div>
             <div className="form-row" hidden={!this.state.showQuizTypes} style={{marginLeft:"175px"}}>
              <div className="form-group mt-1">
                <button
                    name={"Multiple Choice Question"}
                    className="btn btn-sm btn-info"
                    onClick={this.onClickNewContent}
                >Multiple Choice
                </button>
              </div>
              <div className="form-group ml-2 mt-1">
                <button
                  name={"Word Shuffle Question"}
                  className="btn btn-sm btn-info"
                  onClick={this.onClickNewContent}
                  >Word Shuffle
                </button>
              </div>
             </div>
            {this.state.pages.length > 0 &&
            <div className="form-group mt-4 ml-5 mr-5">
              <table className="table table-striped table-hover table-bordered">
                <thead className="thead-dark">
                <tr>
                  <th scope="col" width="130">
                    Display Order
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">Page Type</th>
                  <th scope="col">Options</th>
                </tr>
                </thead>
                <tbody>
                {this.state.pages.map((val, idx) => {
                  return (
                      <tr key={idx}>
                        <td>
                          <ButtonGroup>
                            <Button className="btn-dark mb-2"
                                    size={"sm"}
                                    onClick={() => this.onChangePageOrder({idx:idx,dir:'up'})}>
                              <ArrowUp className="btn-dark"/>
                            </Button>
                          </ButtonGroup>

                          <ButtonGroup>
                            <Button className="btn-dark mb-2"
                                    size={"sm"}
                                    onClick={() => this.onChangePageOrder({idx:idx,dir:'down'})}>
                              <ArrowDown className="btn-dark"/>
                            </Button>
                          </ButtonGroup>

                        </td>
                        <td onClick={() => {this.onClickEdit({target:{id:idx, value:val.pg_id}})}}>{val.pg_table_name}</td>
                        <td onClick={() => {this.onClickEdit({target:{id:idx, value:val.pg_id}})}}>
                          {val.pg_type}
                          {val.pg_type === "Content Page" &&
                              <p>Phrases:{countOccurences(val.sub_content, "Phrase")}&nbsp;
                                Stories:{countOccurences(val.sub_content, "Text")}</p>
                          }
                        </td>
                        <td>
                          <button
                              className="btn btn-outline-dark"
                              disabled={val.pg_id === null}
                              id={idx}
                              value={val.pg_id}
                              onClick={this.onClickEdit}
                          >
                            Edit
                          </button>
                          <button
                              className="btn btn-outline-dark ml-3"
                              id={idx}
                              value={val.pg_id}
                              onClick={this.onClickDelete}
                          >
                            Delete
                          </button>
                          <ConfirmDelete
                              id={this.state.deleteItemIndex}
                              show={this.state.showDeleteConfirmationModal}
                              closeModalCallback={this.closeDeleteConfirmationModal}
                              deleteFunction={this.onConfirmDelete}
                          />
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </div>}

            <label style={{color: "red"}}>{this.state.errorMessage}</label>
            <div className="form-group">
              <button
                  className="btn btn-dark"
                  onClick={this.saveLesson.bind(this, "stay")}
              >
                {this.state.saveButtonText}
              </button>
            </div>
          </div>
          }
        </div>
    );
  }
}
export default Lesson;
