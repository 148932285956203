/**
 * @module ContentPage-API-Interface
 * @summary Handles  requests and formatting of data in communication with the content page service
 */

import axios from "axios";
import {getAuthorizationHeadersObject} from "../Utils/authorization";

/**
 * Requests A Content Page
 * @summary this horribly named method calls on the endpoint to get a specific content page using the API endpint
 * configured in the .env file
 * @param {string} elementId the Id of the Content page we would like to retrieve
 * @return {Promise<any>} Element Request Response Data containing a content page
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getElement(elementId) {
  let getContentPageURL =
    process.env.REACT_APP_BACKEND_CONTENT_PAGE_URI + elementId;

  let contentPageAPIResponse;
  try {
    contentPageAPIResponse = await axios.request({
      method: "GET",
      url: getContentPageURL,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, refresh your page.",
    };
  }

  return contentPageAPIResponse.data;
}

/**
 * Gets the sub-items configured for this Content Page
 * @summary calls on the Phrase or Text API respectively to fetch the details for a content page sub item, then
 * stores them in a new array.
 * @param contentArray the array of sub-pages (text or phrase) contained in the calling phrase
 * @return {Promise<any>} Array of Sub-Items with their new details
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getContentTableNames(contentArray) {
  let newContentArray = contentArray;
  for (let i = 0; i < contentArray.length; i++) {
    let contentCallURL;
    if (contentArray[i].content_type === "Phrase") {
      contentCallURL =
        process.env.REACT_APP_BACKEND_PHRASE_CNT_URI +
        contentArray[i].content_id;
    } else if (contentArray[i].content_type === "Text") {
      contentCallURL =
        process.env.REACT_APP_BACKEND_TEXT_CNT_URI + contentArray[i].content_id;
    }

    try {
      let content = await axios.request({
        method: "GET",
        url: contentCallURL,
        headers: getAuthorizationHeadersObject().headers,
      });
      newContentArray[i].cnt_table_name = content.data.cnt_table_name;
    } catch (error) {
      return {
        errorStatus: error.response.status,
        errorMessage: "Ops, something went wrong. Please, refresh your page.",
      };
    }
  }
  return newContentArray;
}

/**
 * Calls to add a new Content Page to the Server
 * @param {JSON} contentPage a JSON representation of a full content page
 * @return {Promise<any>} the data from an add response: New ContentPage id
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function addContentPage(contentPage){
  let addContentPageUrl = process.env.REACT_APP_BACKEND_CONTENT_PAGE_URI + "add";
  try{
    return (await axios.post(
        addContentPageUrl,
        contentPage,
        getAuthorizationHeadersObject()
    )).data
  } catch (error){
    console.error(`ERROR ADDING CONTENT PAGE: ${error}`)
    return {
      errorStatus: error.response.status,
      errorMessage: error.response.body,
    }
  }
}


/**
 * Updates a Content Page in the database
 * @param contentPage the full definition of the udpated content page
 * @return {Promise<any>} response data
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function saveContent(contentPage) {
  let contPagePostURL =
    process.env.REACT_APP_BACKEND_CONTENT_PAGE_URI +
    "update/" +
    contentPage.contentPage_id;

  let pageUpdateReqBody = {
    pg_table_name: contentPage.pg_table_name,
    content: contentPage.content,
  };

  try {
    return await axios.post(
        contPagePostURL,
        pageUpdateReqBody,
        getAuthorizationHeadersObject()
    );
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, refresh your page.",
    };
  }
}

// /**
//  * Creates a new text or phrase page in the database.
//  * @summary The legacy means to add content to a page. This is now handled by the content pages themselves. Replaced
//  * by the /newELEMENT and Save workflow.
//  * @deprecated
//  * @param props
//  * @param {string}props.type the type of page to create
//  * @param {string}props.contentPage_id the id of the parent content page
//  * @return {Promise<any>} The URL of the Page to redirect to
//  * @see module:Authorization~getAuthorizationHeadersObject
//  */
// async function addContent(props) {
//   let addContentUrl;
//   let nextRedirectPage =
//     "/lesson/" + props.lesson_id + "/contentPage/" + props.contentPage_id;
//   if (props.type === "Text") {
//     addContentUrl = process.env.REACT_APP_BACKEND_TEXT_CNT_URI + "add";
//     nextRedirectPage = nextRedirectPage + "/text/";
//   } else if (props.type === "Phrase") {
//     addContentUrl = process.env.REACT_APP_BACKEND_PHRASE_CNT_URI + "add";
//     nextRedirectPage = nextRedirectPage + "/phrase/";
//   }
//
//   let getAddContentReqBody = {
//     cnt_page_id: props.contentPage_id,
//   };
//
//   try {
//     const response = await axios.post(
//       addContentUrl,
//       getAddContentReqBody,
//       getAuthorizationHeadersObject()
//     );
//     return { redirectPage: `${nextRedirectPage}${response.data}` };
//   } catch (error) {
//     return {
//       errorStatus: error.response.status,
//       errorMessage: "Ops, something went wrong. Please, refresh your page.",
//     };
//   }
// }

/**
 * Deletes a Content Page Sub-Item from the Database/Content_page
 * @summary checks the type of the content to delete, then makes the appropriate API Calls to delete and unlink the
 * page from the content page in the database.
 * @param props
 * @param {string} props.type the type of the content being deleted
 * @param {string} props.content_id the id of the content to be deleted in the database
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function deleteContent(props) {
  let contPageDeleteURL;
  if (props.content_type === "Text") {
    contPageDeleteURL =
      process.env.REACT_APP_BACKEND_TEXT_CNT_URI + props.content_id;
  } else if (props.content_type === "Phrase") {
    contPageDeleteURL =
      process.env.REACT_APP_BACKEND_PHRASE_CNT_URI + props.content_id;
  }

  try {
    let deleteResponse = await axios.request({
      method: "DELETE",
      url: contPageDeleteURL,
      headers: getAuthorizationHeadersObject().headers,
    });
    return deleteResponse;
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, refresh your page.",
    };
  }
}

const contentAPIInterface = {
  getElement,
  getContentTableNames,
  saveContent,
  addContentPage,
  deleteContent,
};

export default contentAPIInterface
