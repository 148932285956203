import React, { Component } from "react";
import APIInterface from "../../MultipleChoiceQuestion/APIInterface";
let previewUrls = new WeakMap();

/**
 * @module AudioSectionComponent
 */

/**
 * Looks in a cache of blobs for the appropriate blob url, adds it to the cache if not present,
 * and returns the media url
 * @function blobUrl
 * @param {Blob} blob  a blob text representing some media
 * @return {string|any} the blob url for the passed blob media
 * @todo see if we can use the [MediaComponent version]{@link module:MediaSectionComponent~blobUrl}
 */
let blobUrl = (blob) => {
    if (previewUrls.has(blob)) {
        return previewUrls.get(blob);
    } else {
        let url = URL.createObjectURL(blob);
        previewUrls.set(blob, url);
        return url;
    }
};

/**
 * @class
 * A smaller audio player component used for the Audio in question options
 * @todo this whole class can likely be replaced by a [MediaSection]{@link module:MediaSectionComponent~MediaSection}
 */
class AudioPlayer extends Component {

    /**
     * @constructor
     * @param props
     * @param props.audioProps
     * @param {string} props.audioProps.question_id the id of the quiz the quiz option belongs to
     * @param {string} props.audioProps.option_id the id of the option that holds the audio
     * @param props.file_name the name of the file configured for audio here
     */
    constructor(props) {
        super(props);

        this.onChangeFile = this.onChangeFile.bind(this);
        this.onClickDeleteFile = this.onClickDeleteFile.bind(this);

        this.state = {
            audioFilePreview: null,
            audioPreviewOriginalFileName: "",
            audioPreviewMimeType: "",
            audioProps: props.audioProps,
            audioBlob:null,
            hasAudio:false,
            tryGet:true,
        };
    }

    onChangeFile = (_) => {

    };

    /**
     * Takes in a file and returns the appropriate mime-type based on the extension
     * @param {string} file_name the name of a file
     * @return {string} the appropriate mime-type for the type of the passed file
     * @todo see if this can be replaced by the [MediaComponent]{@link module:MediaSectionComponent~MediaSection} version
     */
    getMimeTypeFromFileName(file_name) {
        let nameSplit = file_name.split(".");
        let fileExtension = nameSplit.pop();
        let mimeType = undefined;
        if (fileExtension === "mp3") {
            mimeType = "audio/mpeg";
        }
        return mimeType;
    }

    /**
     * Unused....
     * @return {Promise<void>}
     */
    async loadAudioPreview(){

    }

    /**
     *
     * Unused....
     * @return {Promise<void>}
     */
    async onClickDeleteFile() {
    }

    /**
     * Downloads the audio configured for this Quiz Option if it exists
     * @summary calls on the API to download any audio that was configured for this Quiz Option
     * @return {Promise<*|{errorStatus: *}>} the download, or an error status
     */
    async getAudioAPICall(){

        //If this is a new option, don't try to get audio for it. It isn't going to be there
        if(this.props.audioProps.option_id === "newOption"){
            this.setState({hasAudio: false,tryGet:false});
            return {};
        }

        //Otherwise, go for it!
        let response = await APIInterface.downloadAudio(this.props.audioProps);
        if(response.errorStatus === undefined){
            this.setState({hasAudio: true});
            let blob = new Blob([response.data], {type:"mp3"});
            this.setState({audioBlob:blob})

        } else {
            console.error(`Failed to get Audio: ${response.errorStatus}`);
            this.setState({hasAudio: false,tryGet:false});
        }
        return response;
    }

    /**
     * Renders a new Quiz Option audio display
     * @return {JSX.Element} a AudioPlayer Component
     */
    render() {
        let noAttemptToGetAudio = (this.state.hasAudio === false && this.state.tryGet === true)
        let audioNowUpdated = (this.state.hasAudio === false && this.props.file_name !== "None");
        if(noAttemptToGetAudio || audioNowUpdated) {
            this.getAudioAPICall().catch(err => {
                console.error(`Failed to get Audio: ${err}`)
            });
        }
        let {audioBlob} = this.state
        let audioUrl = audioBlob && blobUrl(audioBlob)
        return (
            <>
                <div className="form-group col-md-6">
                    {audioBlob !== null && (
                        <audio src={audioUrl} controls={true}> </audio>
                    )}
                <label>
                    {this.props.file_name}
                </label>
                </div>
            </>
        );
    }
}

export default AudioPlayer