import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import APIInterface from "../APIInterface";
import {unauthorizedErrorMessage} from "../../Utils/authorization";
import Audio from "../../Utils/Audio/audio.component";

/**
 * @class
 * A Modal that pops up when changing a [QuizOption]{@link module:Question-API-Interface~QuizOption}'s audio
 */
class AnswerModal extends Component {

  /**
   * @constructor
   * @param props
   * @param {string} props.question_id the ID of the [Question]{@link module:Question-API-Interface~Question} the option belongs to
   * @param {module:Question-API-Interface~QuizOption} props.answerObject the ID of the Option being modified
   * @param {number} props.id the index of this quiz option among the other quiz options
   * @param {string} props.answerLetter the letter that is assigned to this quiz option based on idx
   * @param {function} props.closeModalCallback the function to  close the modal
   * @param {function} props.updateAnswerOptionFromModal the function to update the quiz option when changes occur
   */
  constructor(props) {
    super(props);

    this.onClickSaveAnswer = this.onClickSaveAnswer.bind(this);
    this.setAudioState = this.setAudioState.bind(this);
    this.downloadAudio = this.downloadAudio.bind(this);
    this.deleteAudio = this.deleteAudio.bind(this);

    this.state = {
      audioObjectState: undefined,
      saveButtonText: "Save file",
      errorMessage: "",
    };
  }

  /**
   * Updates the audio configured for this Option
   * @param newAudioObjectState
   */
  setAudioState = (newAudioObjectState) => {
    this.setState({
      audioObjectState: newAudioObjectState,
      errorMessage: "",
    });
  };

  /**
   * Downloads audio to the Host machine for the represented [QuizOption]{@link module:Question-API-Interface~QuizOption}
   * @return {Promise<*>}
   */
  async downloadAudio() {
    return await APIInterface.downloadAudio({
      question_id: this.props.question_id,
      option_id: this.props.answerObject._id,
    });
  }

  /**
   * Calls on the API to [delete audio]{@link module:Question-API-Interface~deleteAudio} for the represented QuizOption
   * @return {Promise<*>}
   */
  async deleteAudio() {
    let response = await APIInterface.deleteAudio({
      question_id: this.props.question_id,
      option_id: this.props.answerObject._id,
    });
    if (response.errorStatus === undefined) {
      this.props.updateOptionsCallback(this.props.id, response.data);
    }
    return response;
  }

  /**
   * Calls for the update of a QuizOption, then calls on the parent Question to update it's state accordingly
   * @return {Promise<void>}
   * @see [updateAnswerOption]{@link module:Question-API-Interface~updateAnswerOption}
   */
  async onClickSaveAnswer() {
    if (this.state.audioObjectState === undefined) {
      this.setState({
        saveButtonText: "Save file",
        errorMessage: "A valid file must be selected.",
      });
      return;
    }

    this.setState({
      saveButtonText: "Saving...",
      errorMessage: "",
    });

    let updateOptionObject = {
      option: this.props.answerObject,
      question_id: this.props.question_id,
      audioFile: this.state.audioObjectState.audioFile,
    };
    updateOptionObject.option.audio = this.state.audioObjectState;

    let updateResult = await APIInterface.updateAnswerOption(
      updateOptionObject
    );

    if (updateResult.errorStatus === undefined) {
      this.setState({
        saveButtonText: "Save file",
        errorMessage: "",
      });
      this.props.updateOptionsCallback(this.props.id, updateResult.data);
      this.props.closeModalCallback();
    } else {
      if (updateResult.errorStatus === 401) {
        this.setState({
          errorMessage: unauthorizedErrorMessage,
          saveButtonText: "Save File (Login first)",
        });
        window.open("/login", "_blank");
      } else {
        this.setState({
          errorMessage: updateResult.errorMessage,
          saveButtonText: "Save file",
        });
      }
    }
  }

  /**
   * Renders an Answer Modal
   * @return {JSX.Element} the Answer Modal
   * @see Audio
   */
  render() {
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.closeModalCallback}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Answer {this.props.answerLetter}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Audio
                onChangeAudioFile={this.setAudioState}
                downloadAudioAPICall={this.downloadAudio}
                deleteAudioAPICall={this.deleteAudio}
                reloadPageAfterDelete={false}
                elementId={this.props.question_id}>
              {this.props.answerObject.audio}
            </Audio>
            <label style={{ color: "red" }}>{this.state.errorMessage}</label>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={this.onClickSaveAnswer}>
              {this.state.saveButtonText}
            </Button>
            <Button variant="secondary" onClick={this.props.closeModalCallback}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AnswerModal;
