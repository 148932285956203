/**
 * @module Authorization
 */

/**
 * A constructor for the JSON Header object
 * @function getAuthorizationHeadersObject
 * @summary grabs the access_token jwt from browser storage and assigns it withing the nested headers object
 * @return {{headers: {Authorization: string}}} the header object w/ access token
 */
export function getAuthorizationHeadersObject() {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
}

/**
 * Message for expired access
 * @type {string}
 */
export const unauthorizedErrorMessage =
  "FAILED. Your access has expired. Please, login in the new window opened and come back to save your work on this window afterwards. DO NOT close this window unless you want to discard your changes.";

/**
 * Message for unauthorized access
 * @type {string}
 */
export const unauthorizedAccessToPage =
  "You're not authorized to access this page.";
