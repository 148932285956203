import axios from "axios";

/**
 * @module Registration-API-Interface
 */

/**
 * Registers a new User
 * @param props
 * @param {string} props.fullElement.email the email of the user to be registered
 * @param {string} props.fullElement.username the username of the user to be registered
 * @param {string} props.fullElement.password the password of the user to be registered
 * @return {Promise<any>}
 */
async function registerUser(props) {
  let loginURL = process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "register";
  let requestBody = {
    email: props.fullElement.email,
    username: props.fullElement.username,
    password: props.fullElement.password,
  };

  try {
    return await axios.post(loginURL, requestBody);
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "The registration failed.",
    };
  }
}

const registerAPIInterface = {
  registerUser,
};

export default registerAPIInterface;
