import React, { Component } from "react";
import APIInterface from "./APIInterface";

/**
 * @class
 * A sub component of the [RequestsManagement]{@link RequestsManagement} page that displays a table of users
 * pending approval
 */
class OpenRequestsTable extends Component {

  /**
   * @constructor
   * @param props
   * @param props.children the list of user requests to represent as a table, passed in the tag body
   */
  constructor(props) {
    super(props);

    this.onClickMakeAuthor = this.onClickMakeAuthor.bind(this);
    this.onClickMakeEditor = this.onClickMakeEditor.bind(this);
    this.onClickMakeAdmin = this.onClickMakeAdmin.bind(this);
    this.onClickDenyRequest = this.onClickDenyRequest.bind(this);
  }

  /**
   * Assigns the author role to the target user
   * @param user - the user that we are making an author
   * @return {Promise<void>}
   */
  async onClickMakeAuthor(user) {
    let response = await APIInterface.updateRole(user, "author");
    if (response.errorStatus === undefined) {
      await APIInterface.sendEmail(user,"author")
      window.location = "/requestsManagement";
    } else if (response.error === 401) {
      window.location = "/login";
    } else {
      this.setState({
        errorMessage: response.errorMessage,
      });
    }
  }

  /**
   * Assigns the editor role to the target user
   * @param user - the user that we are making an editor
   * @return {Promise<void>}
   */
  async onClickMakeEditor(user) {
    let response = await APIInterface.updateRole(user, "editor");
    if (response.errorStatus === undefined) {
      await APIInterface.sendEmail(user,"editor")
      window.location = "/requestsManagement";
    } else if (response.error === 401) {
      window.location = "/login";
    } else {
      this.setState({
        errorMessage: response.errorMessage,
      });
    }
  }

  /**
   * Assigns the ReadOnly role to the target user
   * @param user - user that we are making a reader
   * @return {Promise<void>}
   */
  async onClickMakeReader(user) {
    let response = await APIInterface.updateRole(user,"ReadOnly");
    if (response.errorStatus === undefined) {
      await APIInterface.sendEmail(user,"ReadOnly")
      window.location = "/requestsManagement";
    } else if (response.error === 401) {
      window.location = "/login";
    } else {
      this.setState({
        errorMessage: response.errorMessage,
      });
    }
  }

  /**
   * Assigns the Admin role to the target user
   * @param user - user that we want to make an admin
   * @return {Promise<void>}
   */
  async onClickMakeAdmin(user) {
    let response = await APIInterface.updateRole(user, "admin");
    if (response.errorStatus === undefined) {
      await APIInterface.sendEmail(user,"admin")
      window.location = "/requestsManagement";
    } else if (response.error === 401) {
      window.location = "/login";
    } else {
      this.setState({
        errorMessage: response.errorMessage,
      });
    }
  }

  /**
   * Denies a users request for access
   * @param {string} user.email the email of the user to deny the re3quest of
   * @return {Promise<void>}
   */
  async onClickDenyRequest(user){
    let response  = await APIInterface.denyRequest(user.email);
    if(response.errorStatus === undefined){
      this.removeUserRequest(user.email);
    } else if (response.errorStatus === 401){
      console.log(`got 401'd`);
    } else {
      this.setState({errorMessage:response.errorMessage});
    }
  }

  /**
   * Removes the GUI representation of an access request from UI. And updates
   * the parent with the new state
   * @param {string} email the email related to the pending request
   */
  removeUserRequest(email) {
    //Grab the index
    let justEmails = this.props.children.map(reqs => reqs.email);

    //Delete the element....
    let removeMe = justEmails.indexOf(email);
    this.props.children.splice(removeMe,1);
    console.log(`removing req with email: ${email} --> ${JSON.stringify(this.props.children)}...`);
    this.props.updateParentList(this.props.children);
  }

  /**
   * Renders a RequestManagement Table
   * @return {JSX.Element} the Request Management Table
   * @see RequestsManagement
   */
  render() {
    return (
      <div>
        <h5 className="font-weight-bold">Access Requests</h5>

        <table className="table table-striped table-bordered mt-4 mb-4">
          <thead className="thead-dark">
            <tr>
              <th scope="col">E-mail</th>
              <th scope="col">Username</th>
              <th scope="col" width="400">
                Options
              </th>
            </tr>
          </thead>

          <tbody>
            {this.props.children.map((val, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <label>{val.email}</label>
                  </td>
                  <td>
                    <label>{val.username}</label>
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-dark"
                      onClick={() => this.onClickMakeReader(val)}
                    >
                      Approve as API reader
                    </button>
                    <button
                      className="btn btn-outline-dark ml-3"
                      onClick={() => this.onClickMakeEditor(val)}
                    >
                      Approve as Editor
                    </button>
                    <button
                      className="btn btn-outline-dark mt-2"
                      onClick={() => this.onClickMakeAuthor(val)}
                    >
                      Approve as Author
                    </button>
                    <button
                      className="btn btn-outline-dark ml-3 mt-2"
                      onClick={() => this.onClickMakeAdmin(val)}
                    >
                      Approve as Admin
                    </button>
                    <button
                        className="btn btn-dark mt-2"
                        onClick={() => this.onClickDenyRequest(val)}
                    >
                      Deny Request
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default OpenRequestsTable;
