import React, { Component } from "react";
import { Link } from "react-router-dom";

/**
 * @class Navbar
 * The Navbar that exists throughout the app, uses
 */
class Navbar extends Component {

    /**
     * @constructor
     * @param props
     * @param {boolean} props.isAuthenticated true/false is there an auth token in storage
     * @param {string} props.role the current user role stored in browser storage
     *
     */
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: props.isAuthenticated?.length > 0,
            role:props.role,
        }
    }

    async componentDidMount(){
        //If we are previously authenticated, and find ourselves back at the login page, this means we timed out.
        //this means we shouldn't have a nav-bar
        if(this.state.isAuthenticated && window.location.href.search("login") > 0){
            this.setState({isAuthenticated:false});
            localStorage.clear();
        }

    }

    /**
     * Removes a users token & role from the browser
     */
    onLogout() {
        localStorage.removeItem("access_token")
        localStorage.removeItem("user_role")
    }

    /**
     * This Navbar has Home, and Logout. It is for Basic Users
     * @summary *NOTE* - There is no such thing as a "base user". Admin, Author, and Editor are the only roles that can
     * interact with the teacher app. Why the read-only user role is revealed here is a mystery to me - fbw.
     * @return {JSX.Element}
     */
    baseNavbar(){
        return (
            <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
                <Link to="/" className="navbar-brand">
                    Nisotak Teacher Tool
                </Link>
                {this.state.isAuthenticated &&
                    <Link to="/login" className="text-white small mr-2" onClick={() => {
                        this.setState({isAuthenticated: false, role: ""});
                        this.onLogout();
                    }}>
                        Logout
                    </Link>
                }
                <Link to="/contact" className="text-white small ml-auto">
                    Contact Us
                </Link>
            </nav>

        );
    }

    /**
     * This Navbar has Home, Language Management, User Management, Request Management, and Logout. It is for Admin Users
     * @return {JSX.Element}
     */
    adminNavbar(){
        return(
            <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
                <Link to="/" className="navbar-brand">
                    Nisotak Teacher Tool
                </Link>
                <Link to="/usersManagement" className="text-white small mr-1">
                    Manage Users
                </Link>
                <Link to="/requestsManagement" className="text-white small mr-2">
                    Manage Requests
                </Link>
                <Link to="/languageManagement" className="text-white small mr-3">
                    Manage Languages
                </Link>
                <Link to="/login" className="text-white small mr-4" onClick={() => {
                    this.setState({isAuthenticated:false, role:""});
                    this.onLogout();
                }}>
                    Logout
                </Link>
                <Link to="/contact" className="text-white small ml-auto">
                    Contact Us
                </Link>
            </nav>
        )
    }

    /**
     * This Navbar has Home, Language Management, and Logout. It is for Author and Editor Users
     * @return {JSX.Element}
     */
    privilegedNavbar(){
        return(
            <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
                <Link to="/" className="navbar-brand">
                    Nisotak Teacher Tool
                </Link>
                <Link to="/languageManagement" className="text-white small mr-3">
                    Manage Languages
                </Link>
                <Link to="/login" className="text-white small mr-4" onClick={() => {
                    this.setState({isAuthenticated:false, role:""});
                    this.onLogout();
                }}>
                    Logout
                </Link>
                <Link to="/contact" className="text-white small ml-auto">
                    Contact Us
                </Link>
            </nav>
        )
    }

    /**
     * Renders a navbar based on user permissions
     * @return {JSX.Element} a Navbar Component
     */
    render() {
        let role =this.state.role;
    if (localStorage.getItem('access_token') == null){
        return this.baseNavbar();
    } else if(role === 'admin'){
        return this.adminNavbar()
    } else if (role === 'author' || role === 'editor'){
      return this.privilegedNavbar();
    } else {
        return this.baseNavbar()
    }
  }
}

export default Navbar