import React, { Component } from "react";
import OpenRequestsTable from "./openRequestsTable";
import APIInterface from "./APIInterface";
import { unauthorizedAccessToPage } from "../../Utils/authorization";
import LoadingSpinner from "../../Utils/LoadingSpinner";
import {Breadcrumb} from "react-bootstrap";

/**
 * @class
 * Manages the approval of users in the system
 * @todo notice, it does no disapproval, this is not desired, see issue #99
 */
class RequestsManagement extends Component {

  /**
   * @constructor
   * @param props the payload needed to create the RequestsManagement Page
   */
  constructor(props) {
    super(props);

    this.state = {
      requests: [],
      errorMessage: "",
      showLoading: true,
    };
  }

  /**
   * Populates the page with current user requests
   * @summary calls [getElement]{@link module:ManageRequests-API-Interface~getElement} to get all active users,
   * then sets the state to reflect the returned list or shows error
   * @return {Promise<void>}
   */
  async componentDidMount() {
    let requests = await APIInterface.getElement();

    if (requests.errorStatus === undefined) {
      this.setState({
        requests: requests,
        showLoading:false,
      });
    } else if (requests.errorStatus === 401) {
      window.location = "/login";
    } else if (requests.errorStatus === 403) {
      this.setState({
        errorMessage: unauthorizedAccessToPage,
        showLoading:false
      });
    } else {
      this.setState({
        errorMessage: requests.errorMessage,
        showLoading:false
      });
    }
  }

  /**
   * Renders the RequestsManagement Page
   * @return {JSX.Element} the Request Management Page
   * @see LoadingSpinner
   * @see OpenRequestsTable
   */
  render() {
    return (
      <div className="ml-3 mr-4">
        {this.state.showLoading && <LoadingSpinner top={'50%'} left={'50%'}/>}

        {!this.state.showLoading &&
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Manage Requests</Breadcrumb.Item>
          </Breadcrumb>
          <OpenRequestsTable
          updateParentList = {(reqList) => this.setState({requests:reqList})}>
            {this.state.requests}
          </OpenRequestsTable>
        </div>
        }
      </div>
    );
  }
}
export default RequestsManagement;