import React, { Component } from "react";
import MediaSection from "./Media/media.component";

/**
 * @class
 * A sub-component that has 2 text input fields, an Audio input, and an Image input
 */
class TwoTextInputWithAudioAndImageSection extends Component {

  /**
   * @constructor
   * @param props
   * @param {function} props.updateTextStateFunction the function that updates the parents state on text input change
   * @param {function} props.updateAudioStateFunction the function that updates the parents audio on change
   * @param {function} props.updateImageStateFunction the function that updates the parents image on change
   * @param {function} props.saveFunction the function that will persist changes to the database
   * @param {APIInterface} props.APIInterface the API interaction helper for the parent class
   * @param {string} props.elementId the id of the parent's represented DBO
   */
  constructor(props) {
    super(props);

    this.onChangeIndigenousText = this.onChangeIndigenousText.bind(this);
    this.onChangeEnglishText = this.onChangeEnglishText.bind(this);
    this.onChangeAudio = this.onChangeAudio.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.downloadAudio = this.downloadAudio.bind(this);
    this.downloadImage = this.downloadImage.bind(this);
    this.deleteAudio = this.deleteAudio.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  /**
   * Listens for a change of the Taught language text input and calls for the update of parent state on change.
   * @param e
   * @param {string} e.target.value the event containing the updated entry to the indigenous text input
   * @listens onchange
   */
  onChangeIndigenousText = (e) => {
    this.props.updateTextStateFunction({
      text_indigenous: e.target.value,
      text_english: this.props.children.text_english,
    });
  };

  /**
   * Listens for a change of the English text input and calls for the update of parent state on change.
   * @param e
   * @param {string} e.target.value the event containing the updated entry to the English text input
   * @listens onchange
   */
  onChangeEnglishText = (e) => {
    this.props.updateTextStateFunction({
      text_english: e.target.value,
      text_indigenous: this.props.children.text_indigenous,
    });
  };

  /**
   * Takes in an updated audio definition and calls on parent to update it's state with it
   * @param audioObject
   * @param audioObject.media
   * @param {string} audioObject.media.file_path not sure... normally empty when received
   * @param {string} audioObject.media.fileErrorFeedbackMessage not sure... normally empty when received
   * @param {string} audioObject.media.file_name the name of the audio file being uploaded
   * @param {string} audioObject.mediaFile not sure... this is normally empty when received
   */
  onChangeAudio = (audioObject) => {
    this.props.updateAudioStateFunction(
      {
        audio: audioObject.media,
      },
      audioObject.mediaFile
    );
  };

  /**
   * Takes in an updated image definition and calls on parent to update it's state with it
   * @param imageObject
   * @param imageObject.media
   * @param {string} imageObject.media.file_path not sure... normally empty when received
   * @param {string} imageObject.media.fileErrorFeedbackMessage not sure... normally empty when received
   * @param {string} imageObject.media.file_name the name of the image file being uploaded
   * @param {string} imageObject.mediaFile not sure... this is normally empty when received
   */
  onChangeImage = (imageObject) => {
    console.log(`Received image object: ${JSON.stringify(imageObject)}`)
    this.props.updateImageStateFunction(
      {
        image: imageObject.media,
      },
      imageObject.mediaFile
    );
  };

  /**
   * Calls on the API to retrieve audio from the database for parent's DBO
   * @return {Promise<AxiosResponse<any>>}
   */
  async downloadAudio() {
    let response = await this.props.APIInterface.downloadAudio({
      element_id: this.props.elementId,
    });
    return response;
  }

  /**
   * Calls on the API to retrieve image from the database for parent's DBO
   * @return {Promise<AxiosResponse<any>>}
   */
  async downloadImage() {
    let response = await this.props.APIInterface.downloadImage({
      element_id: this.props.elementId,
    });
    return response;
  }

  /**
   * Calls on the API to delete audio from the parents DBO
   * @return {AxiosResponse<any>}
   */
  async deleteAudio() {
    let response = await this.props.APIInterface.deleteAudio({
      element_id: this.props.elementId,
    });
    return response;
  }

  /**
   * Calls on the API to delete image from the parents DBO
   * @return {AxiosResponse<any>}
   */
  async deleteImage() {
    let response = await this.props.APIInterface.deleteImage({
      element_id: this.props.elementId,
    });
    return response;
  }

  /**
   * Creates the DOM element containing English/Taught Language text fields and
   [Media Sections]{@link module:MediaSectionComponent~MediaSection} for Audio & Images
   * @return {JSX.Element} a TwoTextInputWithAudioAndImageSection Component
   * */
  render() {
    return (
      <div>
        <h5 className="font-weight-bold">
          {this.props.children.sectionTitle}:
        </h5>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label className="font-weight-bold">Indigenous Language</label>
            <textarea
              rows="6"
              required
              className="form-control"
              placeholder={this.props.children.text_indigenous}
              value={this.props.children.text_indigenous}
              onChange={this.onChangeIndigenousText}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="font-weight-bold">English</label>
            <textarea
              rows="6"
              required
              className="form-control"
              placeholder={this.props.children.text_english}
              value={this.props.children.text_english}
              onChange={this.onChangeEnglishText}
            />
          </div>
        </div>


        {this.props.elementId.toString().includes("new") &&
              <button
                  className="btn btn-dark"
                  onClick={this.props.saveFunction}>
                Add Media
              </button>
        }
        {!this.props.elementId.toString().includes("new") &&
        <div className="form-row ml-1">
          <MediaSection
              onChangeFile={this.onChangeImage}
              downloadMediaAPICall={this.downloadImage}
              deleteMediaAPICall={this.deleteImage}
              reloadPageAfterDelete={true}
              title="Image"
              mediaType={"image"}
          >
            {this.props.children.image}
          </MediaSection>

          <MediaSection
              onChangeFile={this.onChangeAudio}
              downloadMediaAPICall={this.downloadAudio}
              deleteMediaAPICall={this.deleteAudio}
              reloadPageAfterDelete={true}
              title="Audio"
              mediaType={"audio"}
          >
            {this.props.children.audio}
          </MediaSection>
        </div>
        }
      </div>
    );
  }
}
export default TwoTextInputWithAudioAndImageSection
