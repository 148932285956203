import {Spinner} from "react-bootstrap";
import React, { Component } from "react";

/**
 * @class
 * A Loading spinner to be displayed in front of everything when loading
 * @property {string} props.top the numerical % to distance from top
 * @property {string} props.left the numerical % to distance from left side
 */
class LoadingSpinner extends Component {

    /** Makes a new spinner element
     * @method
     * @return {JSX.Element} the new spinner element
     */
    render(){
        return(
            <div style={{position:'absolute',top:this.props.top,left:this.props.left}}>
                <Spinner animation={"border"} variant={"primary"}/>
            </div>
        )
    }
}

export default LoadingSpinner