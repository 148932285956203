import axios from "axios";

/**
 * @module Login-API-Interface
 */

/**
 * Logs in a user
 * @summary takes in user credentials in exchange for an access token
 * @param props
 * @param props.fullElement
 * @param {string} props.fullElement.email the users email
 * @param {string} props.fullElement.password the users password
 * @return {Promise<any>}
 */
async function login(props) {
  let loginURL = process.env.REACT_APP_BACKEND_ACCESS_CONTROL_URI + "login";
  let requestBody = {
    email: props.fullElement.email,
    password: props.fullElement.password,
  };

  try {
    return await axios.post(loginURL, requestBody);
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage:
        "Your login failed. Please, verify both email and password.",
    };
  }
}

const loginAPIInterface = {
  login,
};

export default loginAPIInterface;