import axios from "axios";
import {getAuthorizationHeadersObject} from "../Utils/authorization";

/**
 * @module Lesson-API-Interface
 */

/**
 * @typedef Lesson
 * @property {string} author the author of the lesson
 * @property {string} knowledge_source the knowledge source that led to the creation of this lesson
 * @property {string} dialect the dialect of the taught language
 * @property {string} location the location / locality of the lessons content
 * @property {"Easy"|"Normal"|"Hard"} difficulty how difficult this lesson is
 * @property {"Beginner"|"Intermediate"|"Advanced"} level the level this lesson is
 * @property {string} title_cree the title of the lesson in the taught language
 * @property {string} intro_cree the intro of the lesson in the taught language
 * @property {string} topic_cree the topic of the lesson in the taught language
 * @property {string} title_eng the title of the lesson in english
 * @property {string} intro_eng the intro of the lesson in english
 * @property {string} topic_eng the topic of the lesson in english
 * @property {true|false} published whether or not this lesson has been published for viewing/access
 * @property {Audio} audio the audio for this lesson
 * @property {Image} image the image for this lesson
 * @property {Page[]} pages the list of MCQuestions/Content Pages in the lesson.
 */

/**
 * @typedef Page
 * @property {string} _id the id of the item in the pages list
 * @property {string} pg_id the id of a page in the database
 * @property {string} pg_table_name the name of the page
 * @property {"Content Page"|"Multiple Choice Question"} pg_type the type of the page
 * @property {number} pg_display_order the order of this page in the lesson flow.
 * @property {string[]} sub_content the type of sub-items that make up this content (i.e. phrase, story);
 */

/**
 * Gets all of the Lessons
 * @summary makes a call to the API to retrieve all lessons the caller can see
 * @return {Promise<any>} a JSON representaton of a [Lesson]{@link module:Lesson-API-Interface~Lesson}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getAllLessons() {
  const getLessonsUrl = process.env.REACT_APP_BACKEND_LESSON_URI;

  let lessonsResponse;
  try {
    lessonsResponse = await axios.request({
      method: "GET",
      url: getLessonsUrl,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, refresh your page.",
    };
  }

  return lessonsResponse.data;
}

/**
 * Adds a lesson to the database
 * @param {Lesson} lesson the JSON representation of a lesson
 * @return {Promise<any>} the ID of the new lesson
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function addLesson(lesson) {
  const addLessonUrl = process.env.REACT_APP_BACKEND_LESSON_URI + "add";

  try {
    const response = await axios.post(
      addLessonUrl,
      lesson,
      getAuthorizationHeadersObject()
    );
    return response.data;
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, refresh your page.",
    };
  }
}

/**
 * Deletes a lesson from the database
 * @summary calls on the API to delete a lesson from the database
 * @param {string} lessonId the ID of the lesson to delete
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function deleteLesson(lessonId) {
  let deleteURL = process.env.REACT_APP_BACKEND_LESSON_URI + lessonId;

  try {
    return await axios.request({
      method: "DELETE",
      url: deleteURL,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
    };
  }
}

/**
 * Gets a Lesson from the database
 * @summary again... this is an awful method name. Takes in the ID of a lesson to retrieve, and requests it from the DB
 * @param {string} elementId the ID of the lesson to retrieve
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getElement(elementId) {
  let lessonGetUrl = process.env.REACT_APP_BACKEND_LESSON_URI + elementId;

  let lessonAPIResponse;
  try {
    lessonAPIResponse = await axios.request({
      method: "GET",
      url: lessonGetUrl,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, refresh your page.",
    };
  }

  return lessonAPIResponse.data;
}

/**
 * Requests the details for a Lesson's Content
 * @summary Calls for the retrival of each lesson Sub-Item then
 * @param {Array<Page>} pagesArray the array of pages this lesson contains
 * @return {Promise<Array<Page>>|Promise<any>}  The updated pages array, now with names. otherwise an error.
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function getContentTableNames(pagesArray) {
  let newPagesArray = pagesArray;

  for (let i = 0; i < pagesArray.length; i++) {
    let contentCallURL;
    if (pagesArray[i].pg_type === "Content Page") {
      contentCallURL =
        process.env.REACT_APP_BACKEND_CONTENT_PAGE_URI + pagesArray[i].pg_id;
    } else if (pagesArray[i].pg_type.includes("Question")) {
      contentCallURL =
        process.env.REACT_APP_BACKEND_MC_QUESTION_URI + pagesArray[i].pg_id;
    }
    try {
      let content = await axios.request({
        method: "GET",
        url: contentCallURL,
        headers: getAuthorizationHeadersObject().headers,
      });
      //Expose the page title to the UI
      newPagesArray[i].pg_table_name = content.data.pg_table_name;

      //Expose the Pages sub-data to the UI
      if(pagesArray[i].pg_type === "Content Page"){
        newPagesArray[i].sub_content = content.data.content.map(c => c.content_type);
      }
      else if (pagesArray[i].pg_type.includes("Question")){
        newPagesArray[i].sub_content = ["NA"];
      }

    } catch (error) {
      //If there was an error, this means that for whatever reason - a page in our list of pages was not able to be fetched
      // Given that it could not be fetched, it should not be allowed in the list. splice it.
      newPagesArray[i]._id = undefined;
      newPagesArray.splice(i,1);
      console.error(`Error Fetching Content for ${JSON.stringify(pagesArray[i])} - ${JSON.stringify(error)}`);
    }
  }
  return newPagesArray;
}

/**
 * Builds the payload to construct a lesson in the database
 * @param {Lesson} lessonObject The complete definition of a Lesson
 * @return {FormData} a curated payload to send off to the Lesson service
 */
function updateLessonRequestObject(lessonObject) {
  const data = new FormData();
  data.append("audio", lessonObject.audioFile);
  data.append("title_cree", lessonObject.title_cree);
  data.append("title_eng", lessonObject.title_eng);
  data.append("intro_cree", lessonObject.intro_cree);
  data.append("intro_eng", lessonObject.intro_eng);
  data.append("topic_cree", lessonObject.topic_cree);
  data.append("topic_eng", lessonObject.topic_eng);
  data.append("location", lessonObject.location);
  data.append("dialect", lessonObject.dialect);
  data.append("knowledge_source", lessonObject.knowledge_source);
  data.append("level", lessonObject.level);
  data.append("difficulty", lessonObject.difficulty);
  data.append("pages", JSON.stringify(lessonObject.pages));
  return data;
}

/**
 * Updates an existing Lesson in the database
 * @summary creates a [request object]{@link module:Lesson-API-Interface~updateLessonRequestObject} then calls on the API to update it
 * @param props
 * @param {Lesson} props.fullElement
 * @param {string} props.fullElement.lesson_id the ID of the lesson to update. Stored as _id in the database
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function updateElement(props) {
  let updateLessonURL =
    process.env.REACT_APP_BACKEND_LESSON_URI +
    "update/" +
    props.fullElement.lesson_id;
  let requestBody = updateLessonRequestObject(props.fullElement);

  try {
    return await axios.post(
        updateLessonURL,
        requestBody,
        getAuthorizationHeadersObject()
    );
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, try again.",
    };
  }
}

/**
 * Gets the base-url to use to handle a content type
 * @param {"Content Page"|"Multiple Choice Question"} contentType the type of content page passed
 * @return {string} the root of the URL to use to make requests for the given content type
 * @see module:Authorization~getAuthorizationHeadersObject
 */
function getContentBaseURL(contentType) {
  if (contentType === "Content Page") {
    return process.env.REACT_APP_BACKEND_CONTENT_PAGE_URI;
  } else if (contentType.includes("Question")) {
    return process.env.REACT_APP_BACKEND_MC_QUESTION_URI;
  } else {
    console.error(`UNKNOWN CONTENT TYPE: ${contentType}`);
    return "";
  }
}

/**
 * Creates a new Content page in the database
 * @deprecated This is now handled by the Content Page. Use [Content Page Api]{@link module:ContentPage-API-Interface~addContentPage}
 * @param {string} lessonId the ID of the lesson to add the content to
 * @param {string} contentType the type of the content to add
 * @return {Promise<any>}
 * @see #getContentBaseURL
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function createContent(lessonId, contentType) {
  let createURL = getContentBaseURL(contentType) + "add";
  const requestBody = { lesson_id: lessonId };

  try {
    return await axios.post(
        createURL,
        requestBody,
        getAuthorizationHeadersObject()
    );
  } catch (error) {
    return {
      errorStatus: error.response.status,
      errorMessage: "Ops, something went wrong. Please, try again.",
    };
  }
}

/**
 * Deletes a content page from the database
 * @summary Determines a [base url]{@link module:Lesson-API-Interface~getContentBaseURL} then calls for it's deletion/removal from the lesson
 * @param {string} contentId the ID of the content page to delete
 * @param {string} contentType the type of the content to delete
 * @param {string} id the id of the page in the list to delete
 * @return {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function deleteContent(contentId, contentType,id) {
  let deleteURL = getContentBaseURL(contentType) + contentId;
  try {
    return axios.delete(deleteURL,{
      headers:getAuthorizationHeadersObject().headers,
      data:{_id:id}
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
    };
  }
}

/**
 * Gets the URL to use to request Audio for a given id
 * @param props
 * @param {string} props.element_id the ID of the item who's audio we want to fetch
 * @returns {string} the URL to use to fetch audio
 */
function getAudioFileURL(props) {
  return (
    process.env.REACT_APP_BACKEND_LESSON_URI +
    "media/audio/" +
    props.element_id
  );
}

/**
 * Wraps The Call to download audio to the calling machine
 * @param props
 * @return {Promise<any>} the file download
 */
async function downloadAudio(props) {
  return await downloadMedia(props, "Audio");
}


/**
 * Preforms the call to get audio from the database
 * @summary takes in the type then uses the either the [Audio URL]{@link module:Text-API-Interface~getAudioFileURL}
 * to request media from the database
 * @param props
 * @param {"Audio"|"Image"} mediaType the type of media to call for the download of
 * @return {Promise<any>} the file download
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function downloadMedia(props, mediaType) {
  let downloadAudioURL = getAudioFileURL(props);

  try {
    return await axios.request({
      method: "GET",
      url: downloadAudioURL,
      headers: getAuthorizationHeadersObject().headers,
      responseType: "blob",
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
    };
  }
}

/**
 * Wraps th e call to delete audio
 * @param props
 * @returns {Promise<any>}
 */
async function deleteAudio(props) {
  return await deleteMedia(props, "Audio");
}

/**
 * Deletes audio from the Lesson
 * @param props
 * @param {"Audio"|"Image"} mediaType the type of media to delete
 * @returns {Promise<any>}
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function deleteMedia(props, mediaType) {
  let deleteAudioURL = getAudioFileURL(props);

  try {
    return await axios.request({
      method: "DELETE",
      url: deleteAudioURL,
      headers: getAuthorizationHeadersObject().headers,
    });
  } catch (error) {
    return {
      errorStatus: error.response.status,
    };
  }
}

/**
 * Sets the lesson as published or unpublished
 * @param props
 * @param {string} props.lesson_id the ID of the lesson to publish/unpublish
 * @param {boolean} props.doPublish true/false do we publish this lesson?
 * @returns {Promise<any>} the response of the publish call..
 * @see module:Authorization~getAuthorizationHeadersObject
 */
async function changePublished(props){
  try{
    let publishURL = process.env.REACT_APP_BACKEND_LESSON_URI + 'publish/' + props.lesson_id;
    return (await axios.post(
        publishURL,
        props,
        getAuthorizationHeadersObject()
    )).data;

  }catch (error){
    console.error(`FAILED TO CHANGE PUBLISH: ${JSON.stringify(error)}`);
    return{
      errorStatus: error.response.status,
      errorMessage: error.response
    }
  }
}

const lessonAPIInterface = {
  getAllLessons,
  addLesson,
  deleteLesson,
  getElement,
  getContentTableNames,
  updateElement,
  createContent,
  deleteContent,
  downloadAudio,
  changePublished,
  deleteAudio,
};

export default lessonAPIInterface;
