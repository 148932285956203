import React from "react";
/**
 * @module CorrectAnswerDropdown
 */

/**
 * Constructs the dropdown that allows users to configure the correct answer
 * @summary constructs a dropdown of letters representing which of the options is correct, and allows it to be
 * configured.
 * @param {Array.<module:Question-API-Interface~QuizOption>} options the options of the quiz
 * @param {function} onChangeFunction the function to call when an answer changes in
 * the [AnswerDropdown]{@link QuestionAnswers#onChangeCorrectOption}
 * @return {JSX.Element} the Correct Option selector
 * @constructor
 */
const CorrectAnswerDropdown = (options, onChangeFunction) => {
  let correctAnswerOptions = ["-"];
  let correctOption = "";
  for (let i = 0; i < options.length; i++) {
    let optionLetterFromInteger = String.fromCharCode(65 + i);
    correctAnswerOptions.push(optionLetterFromInteger);
    if (options[i].is_correct) {
      correctOption = i;
    }
  }

  return (
    <select
      ref="userInput"
      required
      className="form-control col-md-1 ml-2"
      value={correctOption}
      onChange={onChangeFunction}
    >
      {correctAnswerOptions.map((val, idx) => {
        return (
          // value is idx-1 due to first option "-"
          <option key={idx} value={idx - 1}>
            {val}
          </option>
        );
      })}
    </select>
  );
};

export default CorrectAnswerDropdown